<template>
  <div
    class="login-bacckground surface-0 flex align-items-center justify-content-center min-h-screen overflow-hidden"
  >
    <div class="grid justify-content-center p-2 lg:p-0" style="min-width: 60%">
      <div
        class="login-marco-padding col-12 mt-5 xl:mt-0 text-center logo-login"
      >
        <router-link to="/">
          <img
            :src="'layout/images/logo-pagando.svg' "
            alt="Lotomax logo"
            class="login-marco-logo mb-5 width-login"
          />
        </router-link>
      </div>
      <div
        class="col-12 xl:col-6 login-marco"
        style="
          border-radius: 10px;
          padding: 0.3rem;
          background: linear-gradient(
            180deg,
            var(--primary-color),
            rgba(33, 150, 243, 0) 30%
          );
        "
      >
        <div
          class="h-full w-full m-0 py-7 px-4"
          style="
            border-radius: 53px;
            background: linear-gradient(
              180deg,
              var(--surface-50) 38.9%,
              var(--surface-0)
            );
          "
        >
          <div class="w-full md:w-10 mx-auto">
            <div
              class="w-full flex align-items-center justify-content-between mb-5 mt-3"
            >
              <b>Establezca su Contraseña de Acceso al Sistema</b>
            </div>
            <form
              @submit.prevent="handleSubmit(!v$.$invalid)"
              autocomplete="off"
              aria-autocomplete="off"
              class="p-fluid"
            >
              <div class="field mb-5">
                <div class="p-float-label">
                  <Password
                    id="password"
                    v-model="v$.password.$model"
                    inputClass="login-inputtext"
                    autofocus
                    :feedback="false"
                    toggleMask
                  >
                  </Password>
                  <label
                    for="password"
                    class="login-float"
                    :class="{ 'p-error': v$.password.$invalid && submitted }"
                    >Nueva Contraseña*</label
                  >
                </div>
                <small
                  v-if="
                    (v$.password.$invalid && submitted) ||
                    v$.password.$pending.$response
                  "
                  class="p-error"
                  >Ingrese la Nueva Contraseña.
                </small>
              </div>
              <div class="field">
                <div class="p-float-label">
                  <Password
                    id="password2"
                    v-model="v$.password2.$model"
                    inputClass="login-inputtext"
                    :feedback="false"
                    toggleMask
                  >
                  </Password>
                  <label
                    for="password2"
                    class="login-float"
                    :class="{ 'p-error': v$.password2.$invalid && submitted }"
                    >Confirmar Contraseña*</label
                  >
                </div>
                <span v-if="v$.password2.$error && submitted">
                  <small class="p-error">Los Contraseñas no coinciden.</small>
                </span>
                <small
                  v-else-if="
                    (v$.password2.$invalid && submitted) ||
                    v$.password2.$pending.$response
                  "
                  class="p-error"
                  >Confirme la Contraseña.</small
                >
              </div>
              <Button
                label="Enviar"
                class="login-p-button w-full text-xl mt-5"
                :loading="loading"
                type="submit"
              >
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { required, sameAs } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
export default {
  setup: () => ({ v$: useVuelidate() }),
  components: {},
  data() {
    return {
      token: null,
      password: "",
      password2: "",
      submitted: false,
      loading: false,
    };
  },
  created() {
    if (!this.$route.query.token || this.$route.query.token == "") {
      this.$router.replace("/notfound");
    } else this.token = this.$route.query.token;
  },
  methods: {
    handleSubmit(isFormValid) {
      this.submitted = true;
      if (!isFormValid) return;
      this.loading = true;
      this.$store
        .dispatch("users/pwdSignup", {
          password: this.password,
          repeat_password: this.password2,
          token: this.token,
        })
        .then((resp) => {
          this.$toast.add({
            severity: "info",
            detail: resp.data.message,
            life: 3000,
          });
          setTimeout(() => {
            this.$router.push("/");
          }, 3500);
        })
        .catch((err) => {
          if (err.response) {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: err.response.data.errors[0].jwt_token,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    logoColor() {
      if (this.$appState.darkTheme) return "white";
      return "dark";
    },
  },
  validations() {
    return {
      password: {
        required,
      },
      password2: {
        required,
        sameAsPassword: sameAs(this.password),
      },
    };
  },
};
</script>
