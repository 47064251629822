<template>
  <div class="flex justify-content-center surface-section py-6 px-4 md:px-6 lg:px-6" id="statuscontult">
    <div class="text-700 text-center">
      <div class="text-red-500 text-2xl font-bold mb-3"><i class="pi pi-spin pi-cog" style="font-size: 1.25rem"></i>&nbsp;OPERADOR</div>
        <div class="text-900 font-bold text-5xl mb-3">Consulta el estatus de tu registro</div>
          <div class="text-700 text-base mb-5"> Indique el número de RIF, sin guiones, ni puntos, debe completar un máximo de 10 caracteres.<br><b>Ejemplo: J123456789</b>.
          </div>
          <div class="text-700 text-2xl mb-1"><b>Introduzca el R.I.F</b>.
          </div>
          <div class="col-12 md:col-6 lg:col-12 px-8 p-inputgroup phone-inputgroup">
            <span
              class="select-imput-status operador-imput p-inputgroup-addon p-inputgroup-addon-checkbox"
              style="max-width: 75px"
            >
              <Dropdown
                id="rif_code"
                ref="rif_code"
                v-model="rif_prefix"
                class="operador-imput"
                :options="['V', 'E', 'P', 'G', 'J']"
                @focus="$refs.rif_code.show()"
                @change="setInputFocus('rif')"
              />
            </span>
            <InputMask
              id="rif"
              v-model.trim="rif"
              class="imput-status p-inputtext p-component operador-imput "
              mask="9999999999"
              :autoClear="false"
              :unmask="true"
              :slotChar="''"
              autocomplete="off"
              aria-autocomplete="off"
              @click="
                ($event) =>
                  $event.target.setSelectionRange(0, $event.target.value.length)
              "
              @keydown.enter="consultStatus"
            />
          </div>
            <Button label="Consultar" icon="pi pi-search" class="font-bold px-5 py-3 my-3 p-button-raised p-button-rounded white-space-nowrap"
            @click="consultStatus"
            :loading="is_processing"></Button>
          </div>
  </div>

  <div class="grid py-4 px-4 relative" id="statuscontult">
    <Dialog
      :showHeader="false"
      :modal="true"
      :breakpoints="{ '600px': '80vw' }"
      :style="{ width: '500px' }"
      class="p-fluid"
      v-model:visible="showNotificationSuccess"
    >
      <div
        class="card operador-sub_marco-mensaje m-3 flex flex-column align-items-center text-center"
      >
        <i class="pi pi-info-circle mt-7 mb-5 text-green-500 text-8xl"></i>
        <h5>Estatus Actual de su Solicitud.</h5>

        <span class="m-3">
          <h5>
            <b>{{ status }}</b>
          </h5>
        </span>
        <span class="m-3"
          >Esté atento a las notificaciones que se envíen al correo electrónico
          con el que registró su solicitud.
        </span>
        <Button
          label="Aceptar"
          @click="showNotificationSuccess = false"
          class="p-button-rounded border-none my-5 font-light text-white line-height-2 bg-red-500"
        ></Button>
      </div>
    </Dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      rif_prefix: "J",
      rif: "",
      showNotificationSuccess: false,
      is_processing: false,
    };
  },
  methods: {
    setInputFocus(field_name) {
      setTimeout(() => {
        let input = document.getElementById(field_name);
        if (input) {
          input.focus();
        }
      }, 60);
    },
    consultStatus() {
      if (this.rif !== "") {
        this.is_processing = true;
        this.$store
          .dispatch("operators/consultRevisionStatus", {
            rif: this.rif,
            prefix: this.rif_prefix,
          })
          .then((resp) => {
            this.status = resp.data.data;
            this.showNotificationSuccess = true;
            //console.log(resp);
          })
          .catch((err) => {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: err.response.data.errors[0].detail,
              life: 4000,
            });
            //console.log(err);
          })
          .finally(() => {
            this.is_processing = false;
          });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.select-imput-status {
  border-radius: 50px 0px 0px 50px !important;
}
.imput-status {
  border-radius: 0px 50px 50px 0px !important;
}
.phone-inputgroup {
  .p-inputgroup-addon {
    padding: 0;
    width: 145px;
  }
  .p-dropdown {
    border: 0px;
  }

  .p-inputgroup-addon.p-error {
    border-color: #b00020;
  }
  .p-dropdown :deep(.p-dropdown-trigger) {
    width: 1.8rem !important;
  }
}
</style>
