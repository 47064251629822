<template>
  <Dialog
    :modal="true"
    :style="{
      width: '700px',
      maxHeight: '95vh',
      borderRadius: '12px',
      maxWidth: '98vw',
    }"
    class="p-fluid withdrawal-modal"
    @show="onShow"
    @hide="onClose"
    :closable="!request_to_api.length"
    :closeOnEscape="false"
    v-model:visible="show_modal"
  >
    <template #header>
      <span v-show="[0, 1].includes(step)" class="modal-title"
        ><IconSearch /> Consultar y retirar saldo
        <hr
      /></span>
      <span v-show="['2_1'].includes(step)" class="modal-title"
        ><IconSearch /> Cobro de ticket
        <hr
      /></span>
      <span v-show="[2, 3, 4, 5].includes(step)" class="modal-title"
        ><i class="pi pi-wallet"></i> Retirar saldo
        <hr
      /></span>
      <span v-show="step === 7 && !transaction_id" class="modal-title"
        ><IconExclamation /> Confirmar retiro
        <hr
      /></span>
      <span v-show="step === 7 && transaction_id" class="modal-title"
        ><IconSuccess /> Retiro confirmado
        <hr
      /></span>
    </template>
    <div class="balance-withdrawal w-full mt-2">
      <!-- Step 0: Start view -->
      <div v-show="step === 0" class="text-center">
        Al hacer clic en <span class="text-highlight">“Consultar saldo”</span>  le enviarás al cliente un mensaje por Whatsapp al:
        <span class="text-highlight">{{
          activeClient.fk_contact.phone_number
        }}</span
        > <br />y podrá ver el monto de su saldo disponible.
        <br />
        <div class="mt-4 mb-4 text-center"><b>¿Qué deseas hacer?</b></div>
        <br />
        <div class="buttons-container">
          <Button
            label="Consultar saldo"
            class="mr-2"
            @click="requestCheckBalance"
            :loading="request_to_api.includes('request_check_balance')"
          />
          <Button
            label="Retirar saldo"
            class="ml-2 mr-2 main"
            @click="step = 2"
            :disabled="request_to_api.length"
          />
          <Button
            label="Cobrar ticket"
            class="ml-2 main"
            @click="step = '2_1'"
            :disabled="request_to_api.length"
          />
        </div>
      </div>
      <!---->

      <!-- Step 1: Balance check SMS view -->
      <transition mode="out-in">
        <div v-show="step === 1" class="text-center">
          <IconSuccess
            class="block mx-auto mb-3"
            :style="{ width: '80px', height: '80px' }"
          />
          Se ha enviado un mensaje por Whatsapp al:
          <span class="text-highlight">{{
            activeClient.fk_contact.phone_number
          }}</span
          >,<br />
          con la información solicitada de su saldo total disponible.<br />
          <label 
            class="text-center sms-label mt-3 pt-3"
            @click="requestBalanceSMSCode()"
            >Enviar mensaje por SMS.</label>
          <hr />
          <div
            class="flex justify-content-center align-items-center text-left mt-5"
          >
            <span class="text-lg font-semibold"
              >¿El cliente desea retirar su saldo?</span
            >
            <div class="buttons-container ml-3">
              <Button label="No" class="mr-2" @click="show_modal = false" />
              <Button label="Si" class="main ml-2" @click="step = 2" />
            </div>
          </div>
        </div>
      </transition>
      <!---->

      <!-- Step 2: Withdraw amount form and request validation code view -->
      <transition mode="out-in">
        <div v-if="step === 2" class="text-center">
          Al hacer clic en <span class="text-highlight">“Continuar”</span> le enviarás al cliente un mensaje por Whatsapp al:
          <span class="text-highlight">{{
            activeClient.fk_contact.phone_number
          }}</span
          > <br/> con un código de 6 dígitos para poder realizar un retiro de saldo
          <div class="flex justify-content-center text-left mt-5">
            <form autocomplete="off" aria-autocomplete="off">
              <div class="grid">
                <div class="col">
                  <label>Monto a retirar en BUSD <sup>*</sup></label>
                  <InputNumber
                    v-model="amount_to_withdraw"
                    id="i_amount"
                    suffix=" BUSD"
                    mode="decimal"
                    :maxFractionDigits="2"
                    :minFractionDigits="2"
                    locale="en-VE"
                    inputClass="text-xl font-semibold"
                    :disabled="request_to_api.includes('request_validation_code')"
                    @keydown.enter="requestValidationCode"
                    @focus="$event.target.select()"
                  />
                  <span class="text-highlight text-xs mb-5"
                    ><sup>*</sup> El monto mínimo de retiro es de
                    {{ formatNumberToLocale(minimum_withdraw, locale) }}
                    BUSD</span
                  >
              </div>
                <div class="col">
                  <label>Monto en Bolívares <sup>*</sup></label>
                  <InputNumber
                    v-model="amount_to_withdraw_ves"
                    id="i_amount"
                    suffix=" BS"
                    mode="decimal"
                    :maxFractionDigits="2"
                    :minFractionDigits="2"
                    locale="en-VE"
                    inputClass="text-xl font-semibold"
                    :disabled="request_to_api.includes('request_validation_code')"
                    @keydown.enter="requestValidationCode"
                    @focus="$event.target.select()"
                  />
                  <span class="text-highlight text-xs mb-5"
                    ><sup>*</sup> El monto mínimo de retiro es de
                    {{ formatNumberToLocale(this.convert(1)) }}
                    BS</span
                  >
                </div>
              </div>
              <div class="buttons-container mt-5">
                <Button
                  label="Atras"
                  class="mr-2"
                  style="padding: 8px 15px !important"
                  @click="$event => step=0"
                  :disabled="request_to_api.includes('request_validation_code')"
                />
                <Button
                  label="Continuar"
                  class="main ml-2 confirm"
                  @click="requestValidationCode"
                  :loading="request_to_api.includes('request_validation_code')"
                /> 
              </div>
            </form>
          </div>
        </div>
      </transition>
      <!---->

      <!-- Step 2_1: Request ticket serial validation -->
      <transition mode="out-in">
        <div v-if="step === '2_1'" class="text-center">
          Ingrese el Nº de serial del ticket y haga click en <span class="text-highlight">“Continuar”</span> para validar el monto a retirar.
          <div class="flex justify-content-center text-left mt-5">
            <form autocomplete="off" aria-autocomplete="off">
              <label>Nº serial del ticket <sup>*</sup></label>
              <div class="flex justify-content-center flex-nowrap mb-1">
                <InputMask
                  v-model="ticket_serial"
                  :autofocus="true"
                  :disabled="request_to_api.length"
                  class="font-bold text-2xl text-center"
                  mask="999999999"
                  slotChar="_"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  id="i_ticket_serial"
                  @keydown.enter="sendTicketSerial"
                  style="max-width: 200px; letter-spacing: .3rem"
                />
              </div>
              <span class="text-highlight text-xs mb-5"
                ><sup>*</sup> Ingrese el serial del ticket</span
              >
              <div class="buttons-container mt-5">
                <Button
                  label="Atras"
                  class="mr-2"
                  style="padding: 8px 15px !important"
                  @click="$event => step=0"
                  :disabled="request_to_api.length"
                />
                <Button
                  label="Continuar"
                  class="main ml-2 confirm"
                  @click="sendTicketSerial"
                  :loading="request_to_api.includes('send_ticket_serial')"
                /> 
              </div>
            </form>
          </div>
        </div>
      </transition>
      <!---->

      <!-- Step 3: Validation code input form view -->
      <transition mode="out-in">
        <div v-if="step === 3" class="text-center">
          Para poder retirar saldo debes solicitarle al cliente el código
          de 6 dígitos enviado al:
          <span class="text-highlight">{{
            activeClient.fk_contact.phone_number
          }}</span>
          que esta vinculado al documento de identidad:
          <span class="text-highlight">{{ activeClient.dni }}</span>
          <div class="flex justify-content-center text-left mt-5">
            <form autocomplete="off" aria-autocomplete="off">
              <label>Ingrese el código <sup>*</sup></label>
              <div class="flex justify-content-center flex-nowrap mb-3">
                <VuePincodeInput
                  v-model="validation_code"
                  :digits="code_digits"
                  inputClass="flex justify-content-center flex-nowrap border-none border-bottom-2 border-noround text-xl font-semibold text-center"
                  :autofocus="true"
                  :disabled="request_to_api.includes('send_validation_code')"
                />
              </div>
              <span class="text-highlight text-xs mb-5"
                ><sup>*</sup> Ingrese los seis dígitos del código de
                validación</span
              >
              <div class="buttons-container mt-5">
                <Button
                  label="Continuar"
                  class="confirm"
                  @click="sendValidationCode"
                  :loading="request_to_api.includes('send_validation_code')"
                />
              </div>
            </form>
          </div>
          <div class="flex flex-column justify-content-center mt-4 mb-3">
            <span class="mt-4">¿No recibió el código en su teléfono?</span>
            <span v-if="request_to_api.includes('request_validation_code')"
              ><i class="pi pi-spin pi-spinner" /> Reenviando código ...</span
            >
            <a
              v-else
              @click.stop.prevent="requestValidationCode"
              href="javascript:void(0)"
              :disabled="request_to_api.length"
              >Reenviar código</a
            >
          </div>
          <label 
            class="text-center sms-label mt-3 pt-3"
            @click="requestWithdrawSMSCode()"
            >Enviar Código por SMS.</label>
        </div>
      </transition>
      <!---->

      <!-- Step 4: Withdrawal option decision view -->
      <transition>
        <div v-show="step === 4" class="text-center">
          <span class="text-xl font-bold">¿Cómo desea retirar su saldo?</span>
          <div class="flex justify-content-center text-left mt-5">
            <br />
            <div class="buttons-container">
              <Button
                label="En efectivo"
                class="mr-2"
                @click="getPaymentMethods(7)"
                :disabled="request_to_api.length"
              />
              <Button
                label="Por banco en bolívares"
                class="ml-2 main"
                @click="getPaymentMethods(5)"
                :disabled="request_to_api.length"
                :loading="request_to_api.includes('request_payment_methods')"
              />
            </div>
          </div>
        </div>
      </transition>
      <!---->

      <!-- Step 5: Withdrawal wallet -->
      <transition>
        <div v-show="step === 5" class="text-center">
          <div
            class="flex flex-column justify-content-center align-items-center"
          >
            <span
              class="text-xl font-bold border-bottom-1 px-5 pb-1 w-max amount-confirm"
              >{{
                checkAmount
                  ? `${formatNumberToLocale(checkAmount, locale)} BUSD`
                  : ""
              }}</span
            >
            <span class="text-base amount-confirm-converted">{{
              checkAmount
                ? `${formatNumberToLocale(amount_converted, locale)} VES`
                : ""
            }}</span>
          </div>
          <transition>
            <div
              class="flex flex-column justify-content-start text-left mt-3"
              v-if="!selected_payment_method"
            >
              <span class="text-lg font-semibold mb-1">Instrucciones</span>
              <li>
                El retiro mínimo debe ser de
                <b>{{ formatNumberToLocale(minimum_withdraw, locale) }} BUSD</b>
              </li>
              <pay-methods
                :loading="!payment_method_options"
                :paymethods="payment_method_options"
                :credit_amount="checkAmount"
                @changePayMethod="changePayMethod"
                class="min-w-full"
                style="margin: 0px !important; margin-bottom: 20px !important;"
              />
              <div class="buttons-container mt-5">
                <Button
                  label="Atras"
                  class="mr-2"
                  style="padding: 8px 15px !important"
                  @click="$event => step=4"
                  :disabled="request_to_api.length"
                />
              </div>
            </div>
          </transition>
          <transition>
            <div
              v-if="selected_payment_method"
              class="flex flex-column justify-content-center align-items-center"
            >
              <PayMethodForm
                ref="payment_method_form"
                :payment_method="selected_payment_method"
                :is_processing_data="
                  request_to_api.includes('register_payment_method')
                "
                :submitted="form_submitted"
                :form_options="form_options"
                class="payment-method-form mt-5"
              />
              <div class="buttons-container mt-5">
                <Button
                  label="Atrás"
                  class="mr-2"
                  @click="changePayMethod(null)"
                  :disabled="request_to_api.length"
                />
                <Button
                  label="Continuar"
                  class="ml-2 main"
                  @click="registerPaymentMethod"
                  :loading="request_to_api.includes('register_payment_method')"
                />
              </div>
            </div>
          </transition>
        </div>
      </transition>

      <!-- Step 7 Confirmation-->
      <transition>
        <div v-show="step === 7" class="text-center">
          <div
            class="flex flex-column justify-content-center align-items-center"
          >
            <span
              class="text-xl font-bold border-bottom-1 px-5 pb-1 w-max amount-confirm"
              >{{
                checkAmount
                  ? `${formatNumberToLocale(checkAmount, locale)} BUSD`
                  : ""
              }}</span
            >
            <span class="text-base amount-confirm-converted">{{
              checkAmount
                ? `${formatNumberToLocale(amount_converted, locale)} VES`
                : ""
            }}</span>
          </div>
          <transition>
            <div
              v-if="transaction_id"
              class="mt-5 flex flex-column justify-content-center align-items-center"
            >
              <IconSuccess
                class="block mx-auto mb-3"
                :style="{ width: '80px', height: '80px' }"
              />
              <span class="text-lg font-semibold">Número de transacción</span>
              <span>{{ transaction_id }}</span>
            </div>
          </transition>
          <hr />
          <div class="transaction-confirm-info">
            <span class="font-medium">Cliente Titular</span
            ><span class="font-bold text-right pl-2">{{
              `${activeClient.first_name} ${activeClient.last_name}`
            }}</span>
          </div>
          <div class="transaction-confirm-info">
            <span class="font-medium">Documento de identidad</span
            ><span class="font-bold text-right pl-2">{{
              `${activeClient.dni}`
            }}</span>
          </div>
          <div class="transaction-confirm-info">
            <span class="font-medium">Número teléfono</span
            ><span class="font-bold text-right pl-2">{{
              `${activeClient.fk_contact.phone_number}`
            }}</span>
          </div>
          <div class="transaction-confirm-info mt-4">
            <span class="font-medium">Fondos a entregar al cliente</span
            ><span class="font-bold text-right pl-2">{{
              `${formatNumberToLocale(amount_converted, locale)} VES`
            }}</span>
          </div>
          <div class="transaction-confirm-info">
            <span class="font-medium">Fondos a recibir por el cliente</span
            ><span class="font-bold text-right pl-2">{{
              `${formatNumberToLocale(checkAmount, locale)} BUSD`
            }}</span>
          </div>
          <div class="transaction-confirm-info">
            <span class="font-medium">Tasa de conversión - BCV</span
            ><span class="font-bold text-right pl-2">{{
              `1 BUSD = ${formatNumberToLocale(conversion_rate, locale)} VES`
            }}</span>
          </div>

          <div v-if="!transaction_id" class="buttons-container mt-5">
            <Button
              label="Atrás"
              class="mr-2"
              @click="
                {
                  changePayMethod(null);
                  step = user_payment_method ? 5 : 4;
                }
              "
              :disabled="request_to_api.length"
            />
            <Button
              label="Confirmar"
              class="ml-2 main"
              @click="confirmTransaction"
              :loading="request_to_api.includes('confirm_transaction')"
            />
          </div>
          <div v-if="transaction_id" class="buttons-container mt-5">
            <Button
              label="Aceptar"
              class="main"
              @click="show_modal = false"
              :loading="request_to_api.includes('confirm_transaction')"
            />
          </div>
        </div>
      </transition>
      <!---->
    </div>
    <template #footer></template>
  </Dialog>
</template>
<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import IconSearch from "@/components/svg/icons/IconSearch.vue";
import IconSuccess from "@/components/svg/icons/IconSuccess.vue";
import IconExclamation from "@/components/svg/icons/IconExclamation.vue";
import PayMethods from "@/components/ticketoffice/PayMethods.vue";
import PayMethodForm from "@/components/ticketoffice/forms/PayMethodForm.vue";
import VuePincodeInput from "vue3-pincode-input";
import HTTP from "@/service/http";

export default {
  components: {
    IconSearch,
    IconSuccess,
    IconExclamation,
    PayMethods,
    PayMethodForm,
    VuePincodeInput,
  },
  setup() {},
  data() {
    return {
      step: 0,
      minimum_withdraw: 1.0,
      amount_to_withdraw: 0,
      amount_to_withdraw_ves: 0,
      minimum_withdraw_ves: null,
      request_to_api: [],
      locale: "es-VE",
      code_digits: 6,
      validation_code: "",
      amount_converted: 0,
      conversion_rate: 0,
      payment_method_options: [],
      user_payment_method_details: [],
      selected_payment_method: null,
      user_payment_method: null,
      form_submitted: false,
      form_options: {},
      transaction_id: null,
      isFunctionBlocked: false,
      ticket_serial: "",
      is_ticket_prize: false,
    };
  },
  created() {
    this.minimum_withdraw_ves = this.convert(1)
  },
  methods: {
    ...mapMutations("ticketoffice", [
      "setModalBalanceWithdrawal",
      "setClientDialog",
    ]),
    ...mapActions("ticketoffice", ["getUserBalance"]),
    formatNumberToLocale(number, locale = "es-VE") {
      return Number(number).toLocaleString(locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    convert(amount) {
      let moneda = this.monedaOptions.find((e) => e.code === 1);
      return !isNaN(amount) && moneda && !isNaN(moneda.exchange_rate)
        ? Number(amount) * Number(moneda.exchange_rate)
        : Number(0);
    },
    convertDollartoVes() {
      this.amount_to_withdraw_ves = parseFloat(Number(this.amount_to_withdraw * this.convert(1)).toFixed(8))
    },
    convertVestoDollar() {
    this.amount_to_withdraw = 
        parseFloat(Number(this.amount_to_withdraw_ves / this.convert(1)).toFixed(8))
    },
    resetDialog() {
      this.step = 0;
      this.amount_to_withdraw = this.minimum_withdraw;
      this.conversion_rate = 0;
      this.selected_payment_method = null;
      this.user_payment_method = null;
      this.transaction_id = null;
      this.validation_code = "";
      this.ticket_serial = "";
      this.is_ticket_prize = false;
    },
    onShow() {
      if (!this.activeClient || !this.activeClient.dni) {
        //this.setClientDialog(true);
        this.show_modal = false;
        return;
      }
      this.resetDialog();
    },
    onClose() {
      this.resetDialog();
    },
    setInputFocus(input_id) {
      setTimeout(() => {
        let input = document.getElementById(input_id);
        if (input) input.focus();
      }, 60);
    },
    requestBalanceSMSCode(){
      if (this.isFunctionBlocked == false){ 
      this.request_to_api.push("request_check_balance");
      HTTP()
        .get(`/credits/sms-balance-notification?dni=${this.activeClient.dni}`)
        .then((resp) => {
          if (resp.data) {
            this.$store.dispatch("changeSystemMessage", {
              severity: "success",
              detail: 'Se ha enviado un SMS al usuario',
              life: 10000,
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            this.$store.dispatch("changeSystemMessage", {
              severity: "error",
              summary: err.response.data.message,
              detail: this.getFirstError(err.response.data.errors),
              life: 10000,
            });
          }
        })
        .finally(() => {
          this.request_to_api = this.request_to_api.filter(
            (e) => e !== "request_check_balance"
          );
        });
      setTimeout(() => {
        this.isFunctionBlocked = false;
      }, 8000);
      }
    },
    requestWithdrawSMSCode(){
      if (this.isFunctionBlocked == false){ 
      this.request_to_api.push("request_check_balance");
      this.data = {
        amount: this.amount_to_withdraw,
        user_dni: this.activeClient.dni
      }
      HTTP()
        .post("/withdrawals/sms", this.data)
        .then((resp) => {
          if (resp.data) {
            this.$store.dispatch("changeSystemMessage", {
              severity: "success",
              detail: 'Se ha enviado un SMS al usuario',
              life: 10000,
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            this.$store.dispatch("changeSystemMessage", {
              severity: "error",
              summary: err.response.data.message,
              detail: this.getFirstError(err.response.data.errors),
              life: 10000,
            });
          }
        })
        .finally(() => {
          this.request_to_api = this.request_to_api.filter(
            (e) => e !== "request_check_balance"
          );
        });
      setTimeout(() => {
        this.isFunctionBlocked = false;
      }, 8000);
      }
    },
    requestCheckBalance() {
      if (!this.activeClient || !this.activeClient.dni) return;
      this.request_to_api.push("request_check_balance");
      HTTP()
        .get(`/credits/balance-notification?dni=${this.activeClient.dni}`)
        .then((resp) => {
          if (resp.data && resp.data.status === "SUCCESS") {
            this.step = 1;
          }
        })
        .catch((err) => {
          if (err.response) {
            this.$store.dispatch("changeSystemMessage", {
              severity: "error",
              summary: err.response.data.message,
              detail: this.getFirstError(err.response.data.errors),
              life: 10000,
            });
          }
        })
        .finally(() => {
          this.request_to_api = this.request_to_api.filter(
            (e) => e !== "request_check_balance"
          );
        });
    },


    requestValidationCode() {
      if (!this.activeClient || !this.activeClient.dni || !this.checkAmount)
        return;
      let data = {
        user_dni: this.activeClient.dni,
        amount: this.checkAmount,
      };
      this.request_to_api.push("request_validation_code");
      HTTP()
        .post("/withdrawals", data)
        .then((resp) => {
          if (resp.data && resp.data.status === "SUCCESS") {
            this.validation_code = "";
            this.is_ticket_prize = false;
            this.step = 3;
            this.$store.dispatch("changeSystemMessage", {
              severity: "success",
              summary: "Código de validación enviado.",
              detail: "",
              life: 10000,
            });
            let inputs = document.querySelectorAll('.balance-withdrawal .vue-pincode-input');
            if (inputs.length) {
              setTimeout(()=>{
                for(let i=0; i<inputs.length; i++) {
                  console.log(inputs[i].value)
                  inputs[i].value = '';
                }
                inputs[0].focus();
              }, 60); 
            }
          }
        })
        .catch((err) => {
          if (err.response) {
            this.$store.dispatch("changeSystemMessage", {
              severity: "error",
              summary: err.response.data.message,
              detail: this.getFirstError(err.response.data.errors),
              life: 10000,
            });
          }
        })
        .finally(() => {
          this.request_to_api = this.request_to_api.filter(
            (e) => e !== "request_validation_code"
          );
        });
    },

    sendValidationCode() {
      if (
        !this.activeClient ||
        !this.activeClient.dni ||
        !this.checkAmount ||
        !this.checkCode
      )
        return;
      this.request_to_api.push("send_validation_code");
      HTTP()
        .get(`/withdrawals/${this.checkCode}`)
        .then((resp) => {
          this.is_ticket_prize = false;
          if (resp.data && resp.data.status === "SUCCESS") {
            if (resp.data.data.is_valid) this.step = 4;
            else {
              this.step = 3;
              this.$store.dispatch("changeSystemMessage", {
                severity: "error",
                summary: "Código inválido",
                detail:
                  "El código ingresado es inválido o expiró, por favor verifique o solicite un nuevo código de validación.",
                life: 10000,
              });
            }
          }
        })
        .catch((err) => {
          if (err.response) {
            this.$store.dispatch("changeSystemMessage", {
              severity: "error",
              summary: err.response.data.message,
              detail: "El código ingresado es inválido o expiró, por favor verifique o solicite un nuevo código de validación.",
              life: 10000,
            });
            //this.resetDialog();
          }
        })
        .finally(() => {
          this.request_to_api = this.request_to_api.filter(
            (e) => e !== "send_validation_code"
          );
        });
    },
    sendTicketSerial() {
      if (
        !this.activeClient ||
        !this.activeClient.dni ||
        !this.ticket_serial
      )
        return;
      this.request_to_api.push("send_ticket_serial");
      let data = {
        payorder_serial: this.ticket_serial,
        dni: this.activeClient.dni
      }
      HTTP()
        .post(`/withdrawals/ticket-prize`, data)
        .then((resp) => {
          if (resp.data && resp.data.status === "SUCCESS") {
            this.amount_to_withdraw = resp.data.data.amount;
            this.step = 4;
            this.is_ticket_prize = true;
          }
        })
        .catch((err) => {
          if (err.response) {
            this.$store.dispatch("changeSystemMessage", {
              severity: "error",
              summary: "Hubo un error. Revise la información del ticket.",
              detail: err.response.data.message,
              life: 10000,
            });
            //this.resetDialog();
          }
        })
        .finally(() => {
          this.request_to_api = this.request_to_api.filter(
            (e) => e !== "send_ticket_serial"
          );
        });
    },
    getPaymentMethods(step) {
      if (
        !this.activeClient ||
        !this.activeClient.dni ||
        !this.checkAmount ||
        !this.checkCode
      )
        return;
      this.request_to_api.push("request_payment_methods");
      HTTP()
        .get(`/withdrawals/payment-methods?verification_code=${this.checkCode}`)
        .then((resp) => {
          if (resp.data && resp.data.status === "SUCCESS") {
            let item = resp.data.data.payment_methods.find(
              (e) => e.currency_acronym === "VES"
            );
            this.amount_converted = item.convert_amount * this.checkAmount;
            this.conversion_rate = item.convert_amount;
            this.payment_method_options = resp.data.data.payment_methods;
            this.user_payment_method_details =
              resp.data.data.payment_method_details;
            this.step = step;
          } else {
            console.log(resp);
          }
        })
        .catch((err) => {
          if (err.response) {
            this.$store.dispatch("changeSystemMessage", {
              severity: "error",
              summary: err.response.data.message,
              detail: this.getFirstError(err.response.data.errors),
              life: 10000,
            });
          }
        })
        .finally(() => {
          this.request_to_api = this.request_to_api.filter(
            (e) => e !== "request_payment_methods"
          );
        });
    },
    changePayMethod(value) {
      this.form_submitted = false;
      this.selected_payment_method = value;
      if (value !== null) {
        let payment_metod_detail = Array.isArray(
          this.user_payment_method_details
        )
          ? this.user_payment_method_details.findLast(
              (e) => e.fk_payment_method === this.selected_payment_method.id
            )
          : undefined;
        this.form_options = {
          default_values: payment_metod_detail
            ? payment_metod_detail.bank_data
            : {
                dni: this.activeClient.dni,
                account_owner: `${this.activeClient.first_name} ${this.activeClient.last_name}`,
              },
          read_only: {
            dni: true,
            account_owner: true,
          },
          validations: {
            dni: { only_numbers: true },
            account_owner: { only_letters: true },
            account_number: { only_numbers: true },
            num_tel: { only_numbers: true },
          },
        };
      } else this.form_options = {};
    },
    registerPaymentMethod() {
      this.form_submitted = true;
      if (
        !this.activeClient ||
        !this.activeClient.dni ||
        !this.checkAmount ||
        !this.checkCode ||
        this.$refs.payment_method_form.v$.$invalid
      )
        return;
      this.request_to_api.push("register_payment_method");
      let data = {
        fk_payment_method: this.selected_payment_method.id,
        bank_data: {},
      };
      this.selected_payment_method.fk_payment_method_structure.forEach((e) => {
        data.bank_data[e.key] = this.$refs.payment_method_form.account[e.key];
      });
      HTTP()
        .post(
          `/withdrawals/payment-method-details?verification_code=${this.checkCode}`,
          data
        )
        .then((resp) => {
          if (resp.data && resp.data.status === "SUCCESS") {
            this.step = 7;
            this.user_payment_method = resp.data.data;
            /* this.$store.dispatch("changeSystemMessage", {
              severity: "success",
              summary: "Código de validación enviado.",
              detail: "",
              life: 10000,
            }); */
          }
        })
        .catch((err) => {
          if (err.response) {
            this.$store.dispatch("changeSystemMessage", {
              severity: "error",
              summary: err.response.data.message,
              detail: this.getFirstError(err.response.data.errors),
              life: 10000,
            });
          }
        })
        .finally(() => {
          this.request_to_api = this.request_to_api.filter(
            (e) => e !== "register_payment_method"
          );
        });
    },
    confirmTransaction() {
      if (
        !this.activeClient ||
        !this.activeClient.dni ||
        !this.checkAmount ||
        !this.checkCode
      )
        return;
      this.request_to_api.push("confirm_transaction");
      let data = {
        verification_code: this.checkCode,
        withdraw_type: this.user_payment_method
          ? "WALLET"
          : "TICKETOFFICE",
      };
      if (this.user_payment_method)
        data.fk_client_payment_method_detail = this.user_payment_method.id;
      HTTP()
        .patch(`/withdrawals${this.is_ticket_prize ? '/ticket-prize' : ''}`, data)
        .then((resp) => {
          if (resp.data && resp.data.status === "SUCCESS") {
            this.transaction_id = resp.data.data.withdraw_serial;
            this.step = 7;
            //this.user_payment_method = resp.data.data;
            /* this.$store.dispatch("changeSystemMessage", {
              severity: "success",
              summary: "Código de validación enviado.",
              detail: "",
              life: 10000,
            }); */
            this.getUserBalance().catch(err => console.log(err));
          }
        })
        .catch((err) => {
          if (err.response) {
            this.$store.dispatch("changeSystemMessage", {
              severity: "error",
              summary: err.response.data.message,
              detail: this.getFirstError(err.response.data.errors),
              life: 10000,
            });
          }
        })
        .finally(() => {
          this.request_to_api = this.request_to_api.filter(
            (e) => e !== "confirm_transaction"
          );
        });
    },
  },
  computed: {
    ...mapGetters("ticketoffice", [
      "activeClient",
      "getOpenModalBalanceWithdrawal",
    ]),
    ...mapState("ticketoffice", ["monedaOptions"]),
    checkAmount() {
      if (
        !isNaN(String(this.amount_to_withdraw).replace(/,/g, ".")) &&
        Number(String(this.amount_to_withdraw).replace(/,/g, ".")) >=
          this.minimum_withdraw
      )
        return Number(String(this.amount_to_withdraw).replace(/,/g, "."));
      else return false;
    },
    checkCode() {
      if (this.is_ticket_prize && this.ticket_serial) return this.ticket_serial;
      else if (
        this.validation_code.length < this.code_digits ||
        isNaN(this.validation_code)
      )
        return false;
      else return this.validation_code;
    },
    show_modal: {
      get() {
        return this.getOpenModalBalanceWithdrawal;
      },
      set(value) {
        this.setModalBalanceWithdrawal(value);
      },
    },
  },
  watch: {
    step(value) {
      if (value === 2) {
        if (isNaN(this.amount_to_withdraw) || Number(this.amount_to_withdraw) < Number(this.minimum_withdraw)) 
          this.amount_to_withdraw = this.minimum_withdraw;
        this.setInputFocus("i_amount");
      }
      else if (value === "2_1") {
        this.ticket_serial = "";
        this.setInputFocus("i_ticket_serial");
      }
    },
    validation_code(value) {
      if(value && this.step === 3 && this.checkCode) {
        this.sendValidationCode();
      }
    },
    amount_to_withdraw(newVal) {
      if(newVal){
        this.convertDollartoVes();
      }
    },
    amount_to_withdraw_ves(newVal){
      if(newVal){
        this.convertVestoDollar();
      }
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../../assets/styles/_variables.scss";
.modal-title {
  font-size: 24px;
  line-height: 28px;
  color: #404040;
  display: flex;
  justify-items: center;
  align-items: center;
  svg {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  .pi-wallet {
    color: $magenta;
    font-size: 34px;
    margin-right: 10px;
  }
  hr {
    position: fixed;
    top: 50px;
    width: 92%;
  }
}
.text-highlight,
a {
  color: $magenta;
  font-weight: 600;
  &:hover {
    color: $magenta_dark;
  }
}
a[disabled="1"] {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}
.buttons-container {
  display: flex;
  justify-content: center;

  .p-button {
    color: $magenta;
    border: solid 1px $magenta;
    border-radius: 6px;
    background: #fff;
    max-width: fit-content;
    min-width: 50px;
    padding: 10px 15px;
    &.main {
      background: $magenta;
      color: #fff;
    }
    &.confirm {
      background: $magenta;
      color: #fff;
      height: 35px;
    }
  }
  .p-button:hover,
  .p-button:active {
    background: $magenta_dark;
    color: #fff;
  }
}

form,
:deep(.payment-method-form) {
  max-width: 340px;
  label {
    font-weight: 500;
  }
}

.vue-pincode-input-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  row-gap: 1rem;
  margin-top: 10px;
  :deep(input.vue-pincode-input) {
    max-width: 30px;
    margin-right: 10px;
  }
}

.vue-pincode-input-wrapper[disabled="true"] {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}
.transaction-confirm-info {
  display: flex;
  justify-content: space-between;
  margin: 10px 5px;
}
.sms-label {
  color: #CC195A; 
  font-size: 15px; 
  font-weight: bold;
}
.sms-label:hover{
  cursor: pointer;
} 
@media screen and (max-width: 500px) {
  .modal-title {
    font-size: 22px;
  }
  svg {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
}

/* ---------------------------------- */
.v-enter-active {
  transition: opacity 0.8s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
