<template>
  <div class="methods m-4">
    <div class="mt-4">
      <h5>Selecciona tu método de pago</h5>
    </div>
    <hr />
    <div class="flex flex-column">
      <!-- {{paymethods[0]}} -->
      <img src=""/>
      <div v-if="paymethods" class="flex flex-wrap">
        <div
          v-for="(data) in paymethods"
          :key="data.id"
          class="flex payment-method mb-3 mr-3"
          :class="{
            'payment-method-button-selected-color':
              paymethod_selected === data.id,
          }"
          @click="changePayMethod(data)"
        >
          <!-- <img :src="`layout/images/paymentImages/image${index + 1}.svg`"/> -->
          <img :src="getImagePath(data.name)"/>

          <p class="pt-1 pl-2 text-sm">
            {{ data.name }}
          </p>
        </div>
      </div>
    </div>
      <!-- <img :src="getImagePath(item.name)"> -->

  </div>
</template>

<script>
export default {
  props: {
    loading: {
      default: true,
    },
    paymethods: {
      default: null,
    },
    credit_amount: {
      default: null,
    },
  },
  data() {
    return {
      paymethod_selected: null,
    };
  },
  watch: {
    credit_amount() {
      if (!this.credit_amount) {
        this.paymethod_selected = null;
      }
    },
  },
  methods: {
    getRandomColor() {
      let letters = "0123456789ABCDEF";
      let color = "#";

      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }

      return color;
    },
    changePayMethod(value) {
      if (this.credit_amount) {
        this.paymethod_selected = value.id;
      }

      this.$emit("changePayMethod", value);
    },
    getImagePath(name) {
      switch(name) {
        case 'Pago móvil':
          return 'layout/images/paymentImages/image1.svg';
        case 'Transferencia bancaria':
          return 'layout/images/paymentImages/image2.svg';
        case 'Binance Payment':
          return 'layout/images/paymentImages/image3.svg';
        case 'Crédito':
          return '';
        default:
          return '';
      }
    }
  },
};
</script>

<style scoped>
.payment-method {
  flex: 10%;
  max-width: 30%;
  height: 2rem;
  font-size: 14px;
  line-height: 16px;
  color: #404040;
  cursor: pointer;
}
.payment-method:hover {
  color: #8c113e;
  font-weight: 700;
}

.payment-method-button-selected-color {
  color: #8c113e;
  font-weight: 700;
}
h5 {
  color: #8c113e;
}
.methods {
  width: 30rem;
}
</style>
