<template>
  <div class="grid">
    <!-- money details -->
    <div class="col-12 p-0 m-2">
      <div class="grid">
        <div class="col-4 p-0 pr-2" v-if="['operadora', 'taquilla', 'usuario'].includes(role)">
          <div class="box-total-available mt-2">
            <div class="grid p-2">
              <div class="col-12 center">
                <img
                  class="icon-binance-busd"
                  src="/images/binance/icons/binance-busd.svg"
                />
              </div>
              <div class="col-12 center" style="padding-bottom: 0px !important; padding-top:0px">
                <p class="text-total-available" style="margin-right:5px">{{ Number(getTotalUserBalance).toLocaleString('es-VE')}}</p>
                <p class="text-total-available" v-if="renderTicker">BUSD</p>
              </div>
              <div class="col-12 center" style="padding: 0px !important;">
                <p  class="walletBalanceInVES">{{ convert(getTotalUserBalance).toLocaleString('es-VE') }} BS</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-8 p-0" v-if="!['analista-lotomax'].includes(role)"
        :class="{
          'box-total-autogestion': !['operadora', 'taquilla', 'usuario'].includes(role)
        }">
          <div class="box-total-currency-exchange mt-2">
            <div class="grid p-2">
              <div class="col-12 center">
                <p class="text-total-currency-exchange">Total: {{
            currentPlayData &&
            currentPlayData.ticket &&
            !isNaN(currentPlayData.ticket.debt)
              ? Number(Math.floor(currentPlayData.ticket.debt * 100) / 100).toLocaleString('es-VE')
              : Number(0).toFixed(2).toLocaleString('es-VE')
          }} BUSD</p>
              </div>
              <div class="col-12 center">
                <p class="text-converted-currency">
                  {{
                    currentPlayData &&
                    currentPlayData.ticket &&
                    !isNaN(currentPlayData.ticket.total)
                      ? convert(currentPlayData.ticket.total).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, (commas) => { return (commas === ".") ? "," : commas + "."})
                      : Number(0).toFixed(2)
                  }}  BS
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- action buttons -->
    <div class="col-12 p-0 m-2">
      <div class="grid">
        <div class="col-4 p-1" v-if="['operadora', 'taquilla', 'usuario'].includes(role)">
          <Button type="button" class="button" @click="this.$emit('show-credit-modal', true)">
            <IconWallet />
            <span>Wallet</span>
          </Button>
        </div>
        <div class="col-8 p-1" v-if="!['analista-lotomax'].includes(role)"
        ref="receipt_button"
        >
          <Button id="myButton"
          :class="{
          'box-total-autogestion-button': !['operadora', 'taquilla', 'usuario'].includes(role)
        }"
            type="button"
            class="button"
            @click="toggleShowReceipt()"
          >
            <IconReceipt />
            <span>Ver recibo</span>
            <i
              class="pi "
              :class="{'pi-angle-up': showReceipt, 'pi-angle-down': !showReceipt}"
            >
            </i>
          </Button>
        <div
            v-show="showReceipt"
            class="box-ticket-preview mt-2"
            ref="myDiv"
          >
            <TicketPreview
              ref="ticket_preview"
            />
          </div>
  </div> 
      </div>
    </div>
    <PrintTicket
      v-if="showPrint"
      @runPrintTicket="runPrintTicket"
    />
  </div>
</template>

<script>
import HTTP from "@/service/http";
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";
import TicketPreview from "../../components/ticketoffice/TicketPreview.vue";
import IconWallet from "@/components/svg/icons/IconWallet.vue";
import IconReceipt from "@/components/svg/icons/IconReceipt.vue";

import PrintTicket from "../../components/ticketoffice/modals/PrintTicket.vue";

export default {
  components: {
    TicketPreview,
    IconWallet,
    IconReceipt,
    PrintTicket,
  },
  data() {
    return {
      showReceipt: false,
      toPrint: false,
      paymethods: null,
      playData: null,
      convert_amount_ves: null,
      testClick: true,
      renderTicker: true,
    }
  },
    computed: {
    ...mapState("ticketoffice", [
      "showTicketPreview", "showPrint",
      "userBalance", "monedaOptions", 
    ]),
    ...mapGetters("ticketoffice", ["currentPlayData", "getTotalUserBalance"]),
    ...mapGetters("auth", ["role"]),
  },
  methods: {
    ...mapMutations("ticketoffice", [
      "setToggleTicketPreview",
    ]),
    ...mapActions("ticketoffice", [
      "loadTicketOfficeSession", "getCurrencies",
    ]),
    animateButton() {
      const button = document.getElementById('myButton');
        button.classList.add('animate');
        setTimeout(() => {
        button.classList.remove('animate');
        }, 500);
    },
    convert(amount) {
      let moneda = this.monedaOptions.find((e) => e.code === 1)
      let convertedValue = !isNaN(amount) && moneda &&
        !isNaN(moneda.exchange_rate)
        ? Number(amount) * Number(moneda.exchange_rate) 
        : Number(0);
      return convertedValue
    },
    toggleShowReceipt() {
      this.showReceipt = !this.showReceipt;
      this.setToggleTicketPreview(!this.showTicketPreview);
    },
    runPrintTicket() {
      this.$refs["ticket_preview"].toPrint();
    },
    formatMoney(value) {
      return parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 2 }).replace(',', '.');
    },
    getPaymentMethodsDepositCredits() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        HTTP()
          .get("/paymentmethods?deposit-credits")
          .then((resp) => {
            this.paymethods = resp.data.data;
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => (this.loading = false));
      });  
    },
    handleClickOutside() {
    if (this.$refs.myDiv && !this.$refs.myDiv.contains(event.target) && !this.$refs.receipt_button.contains(event.target) ) {
      this.showReceipt = false;
    }
  },
  changeRenderState() {
    if(top.innerWidth < 1300){
      this.renderTicker = false;
    }else {
      this.renderTicker = true;
      console.log(top.innerWidth)
    }
  },
  buttonAnimation() {
    setInterval(() => {
      if(this.currentPlayData && this.currentPlayData.ticket && !isNaN(this.currentPlayData.ticket.debt) && !this.showReceipt){
        this.animationCondition = true;
      } else {
        this.animationCondition = false;
      }
      if(this.animationCondition == true) {
        setInterval(() => {
          if (this.animationCondition == true) {
            this.animateButton();
          }
        }, 2000);
      }
    }, 3000);
  }, 
  },
  created() {
    this.getCurrencies().catch();
    this.changeRenderState()
    //this.getPaymentMethodsDepositCredits();
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
    this.buttonAnimation();
  },
};
</script>

<style scoped>
.walletBalanceInVES{
  font-size: 13px; 
  font-weight: 600
}
.center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-height: fit-content;
}

.icon {
  width: 20px;
}

.icon-binance-busd {
  width: 30px;
  height: 29px;
}
/* #myButton.animate {
  background-color: #cc195b5e;
  transition: background-color 1s;
} */
.box-total-available {
  width: 100%;
  height: 7em;
  background: #EDEDED;
  border-radius: 6px;
}

.text-total-available {
  margin-bottom:1px !important;
  width: auto;
  /* height: 108px; */
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #404040;
}

.box-total-currency-exchange {
  width: 95%;
  height: 7em;
  background: #404040;
  border-radius: 6px;
}

.text-total-currency-exchange {
  width: auto;
  height: 28px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #FFFFFF;
}

.text-converted-currency {
  width: auto;
  height: 19px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #C6C6C6;
}

.button {
  background: #FFF;
  color: #404040;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.625em;
  gap: 0.625em;
  width: 9.4375em;
  border: 1px solid #CC195A;
  border-radius: 6px;
  height: 2.8em;
  width: 96%;
}

.button:hover, .button:focus {
  background: #CC195A;
  color: #FFF;
}

.box-ticket-preview {
  position: absolute;
  right: 3.8em;
  background: #FFF;
  border-radius: 0.5em;
  box-shadow: 0px 0px 10px -4px #212121;
}
/*col*/
.box-total-autogestion {
    position: relative;
  right: -30%;
  width: 70%;
}
.box-total-autogestion-button {
  position: relative;
  right: -45%;
  width: 100%;
}
@media screen and (max-width: 912px) {
  .box-ticket-preview {
    width: 100%;
    right: 0;
  }
}
</style>
