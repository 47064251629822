export default {
  methods: {
    getFirstError(errors) {
      return errors && errors.length > 0 && typeof errors[0] === "object"
        ? typeof errors[0][Object.keys(errors[0])[0]] === "object"
          ? errors[0][Object.keys(errors[0])][0]
          : errors[0][Object.keys(errors[0])]
        : "";
    },
  },
};
