<template>
  <div class="layout-footer notprintContent">
    <img alt="Logo" :src="footerImage()" class="footer-logo" />
    <div class="footer-info">
      <div class="footer-copyright"
        >© 2023 TEC-FIN 212, C.A | Todos los derechos reservados.</div
      >
      <div class="footer-support"
        >Soporte: soporte@pagando.tech | Atención al cliente:
        info@pagando.tech</div
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "AppFooter",
  methods: {
    footerImage() {
      return this.$appState.darkTheme
        ? "layout/images/logo_1.svg"
        : "layout/images/logo_1.svg";
    },
  },
  computed: {
    darkTheme() {
      return this.$appState.darkTheme;
    },
  },
};
</script>
<style lang="scss">
.footer-logo {
  position: absolute;
  left: 25px;
  width: 40px;
  height: 40px;
}
.footer-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-items: center;
}
.footer-copyright {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  margin: 3px;
}
.footer-support {
  font-family: Roboto;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  margin: 3px;
}
</style>
