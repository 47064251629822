import HTTP from "@/service/http";

export default {
  namespaced: true,
  state: {
    transactions: [],
    currencies_options: [],
    payment_methods: [],
  },
  actions: {
    getTransactions({ commit }, params) {
      return new Promise((resolve, reject) => {
        HTTP(...Array(3), { params: params })
          .get("/transactions")
          .then((resp) => {
            commit("setTransactionsList", resp.data);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getTransactionById(s, id) {
      return new Promise((resolve, reject) => {
        HTTP()
          .get("/transactions/" + id)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    changeTransactionStatus({ state }, data) {
      return new Promise((resolve, reject) => {
        HTTP(false, 600000)
          .patch(`/transactions/${data.id}`, {
            transaction_status: data.status,
          })
          .then((resp) => {
            let index = state.transactions.findIndex((e) => e.id === data.id);

            if (index >= 0) {
              state.transactions[index].transaction_status = resp.data.data.transaction_status;
              state.transactions[index].approval_date = resp.data.data.approval_date;
            }

            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  mutations: {
    setTransactionsList: (state, data) => {
      state.transactions = data.data;
    },
  },
  getters: {
    transactionsList: (state) => {
      return state.transactions ? state.transactions : [];
    },
  },
};
