function saveTicketofficeSession(session_data) {
  localStorage.setItem(
    process.env.VUE_APP_TICKETOFFICE_SESSION_KEY,
    JSON.stringify(session_data)
  );
}

function getTicketofficeSession() {
  return JSON.parse(
    localStorage.getItem(process.env.VUE_APP_TICKETOFFICE_SESSION_KEY)
  );
}

export default {
  saveTicketofficeSession,
  getTicketofficeSession,
};
