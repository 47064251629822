<template>
  <div class="grid">
    <div class="xl:col-12 md:col-12 text-center">
      <p class="text-3xl md:text-2xl lg:text-auto title-color font-bold mt-2">
        ¿Desea Anular alguna Juagada?
      </p>
        <button
          v-for="gameAndReference in gameAndReferences.services"
          type="button"
          class="button-get-ticket"
          :class="`button-get-ticket m-1 relative ${ this.cancelOrderData.game === gameAndReference.game ? 'button--active' : ''}`"
          :key="gameAndReference.id"
          @click="cancelOrder(gameAndReference)"
        >
          <div class="text-center">
            <div class="col-4 p-1">
              <i
                style="font-size: 4rem"
              >{{gameAndReference.game}}</i>
            <i
                class="pi pi-check-circle check"
                v-show="this.cancelOrderData.game === gameAndReference.game"
              >
              </i>
            </div>
          </div>
        </button>
    </div>
  </div>
</template>

<script>

export default {
  props: [
    "gameAndReferences"
  ],
    emits: [
    "deletePayOrder",
  ],
  components: {
  },
  data() {
    return {
        cancelOrderData: {
            game: "",
            reference: "",
            id: ""
        }
    }
  },
  methods: {
    cancelOrder(gameAndReference) {
        this.cancelOrderData = {game: gameAndReference.payorder_reference, id: this.gameAndReferences.id}
      this.$emit('deletePayOrder', this.cancelOrderData)
    }
    //     cancelOrder(gameAndReference) {
    //     this.cancelOrderData = {
    //         game: gameAndReference.game,
    //         reference: gameAndReference,
    //         id: this.gameAndReferences.id
    //     }
    //   this.$emit('deletePayOrder', this.cancelOrderData)
    // }
  },
  computed: {

  },
  mounted() {
  },
  created() {
  },
};
</script>

<style scoped>
:deep(.button-get-ticket) {
  width: fit-content;
  padding: 0.5em;
  border-radius: 1rem;
  border: 0rem;
  cursor: pointer;
  background-color: buttonface;
}

.button-get-ticket:disabled,
.button-get-ticket[disabled] {
  pointer-events: none;
}
.button--active {
  box-shadow: inset 4px 4px 4px rgba(0, 0, 0, 0.25);
}
.check {
  top: 5px; right: 5px; position: absolute
}

.p-splitbutton.print {
  justify-content: space-between;
}
:deep(.p-splitbutton.print .p-button.p-splitbutton-menubutton) {
  background-color: transparent !important;
  border-left: #ccc solid 1px;
  color: #000;
}
:deep(.p-splitbutton.print:hover .p-button.p-splitbutton-menubutton) {
  color: #fff;
}
:deep(.print:hover) {
  background: #000;
  color: #fff;
  box-shadow: inset 4px 4px 4px rgba(0, 0, 0, 0.25);
}
.button-get-ticket:hover {
  box-shadow: inset 4px 4px 4px rgba(0, 0, 0, 0.25);
}

@media screen and (max-width: 768px) {
  :deep(.button-get-ticket) {
    width: 20rem;
    height: 6rem;
    border-radius: 1rem;
    border: 0rem;
    cursor: pointer;
    background-color: buttonface;
  }
}
</style>
