export const routeProduction = [
  {
    path: "/ticketoffice-online",
    name: "onlineticketoffice",
    component: () => import(/* webpackChunkName: "ticketoffice" */"@/views/ticketoffice/TicketOffice.vue"),
    meta: {
      title: "Taquilla Online",
    }
  },
];

export const beforeEachProduction = (to, from, next) => {
  document.title = `${to.meta.title ? to.meta.title + " - " : ""}  Pagando`;
  if (to.name !== "onlineticketoffice" ) next("/ticketoffice-online");
  next();
}
