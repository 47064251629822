import HTTP from "@/service/http";

export default {
  namespaced: true,
  state: {
    country_code: "VE", 
    currencies_options: [],
    payment_methods: [],
  },
  actions: {
    getPaymentMethods({ state }, params) {
      return new Promise((resolve, reject) => {
        HTTP(...Array(3), { params: params })
          .get("/paymentmethods")
          .then((resp) => {
            state.payment_methods = resp.data.data;
            resolve(resp.data.data);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    getCurrencies({ state }) {
      HTTP(true)
        .get("/currencies")
        .then((resp) => {
          state.currencies_options = resp.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setExchangeRate({ state }, data) {
      return new Promise((resolve, reject) => {
        HTTP(true)
          .post("/currencies/rates", data)
          .then((resp) => {
            let item = state.currencies_options.find(
              (e) => e.id === resp.data.data.fk_currency
            );
            if (item) item.currency_rate = resp.data.data;
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetchCountryCode({ state }) {
      state.country_code = "VE";
      /* HTTP(true)
        .get("https://ip2c.org/s")
        .then((resp) => {
          const responseText = resp.data;
          const result = (responseText || "").toString();
          if (!result || result[0] !== "1") state.country_code = "VE";
          else state.country_code = result.substr(2, 2);
        })
        .catch((err) => {
          console.log(err);
          state.country_code = "VE";
        }); */
    },
  },
  mutations: {},
  getters: {
    Currencies: (state) => {
      return state.currencies_options;
    },
    PaymentMethods: (state) => {
      return state.payment_methods;
    },
  },
};
