import HTTP from "@/service/http";

export default {
  namespaced: true,
  state: {
    typeNotifications: [],
    // Resend notification
    sendNotificationOptions: [],
    sendPagando: false,
    client: null,
    services: null
  },
  actions: {
    getListTypeNotifications({ commit }, data) {
      return new Promise((resolve, reject) => {
        HTTP(true)
          .get("/notifications/type-notifications", data)
          .then((resp) => {
            commit("setTypeNotifications", resp.data.data);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },
  mutations: {
    setTypeNotifications: (state, data) => {
      state.typeNotifications = data;
    },
    toggleSendNotificationOption: (state, id) => {
      if (state.sendNotificationOptions.includes(id)) {
        state.sendNotificationOptions = state.sendNotificationOptions.filter(item => item != id);
      } else {
        state.sendNotificationOptions.push(id);
      }
    },
    setSendNotificationOptions: (state, data) => {
      state.sendNotificationOptions = data;
    },
    setClient: (state, data) => {
      state.client = data;
    },
    resetServices: (state) => {
      state.services = null;
    },
    setServices: (state, data) => {
      state.services = data;
    },
    resetSendPagando: (state) => {
      state.sendPagando = false;
    },
    setSendPagando: (state) => {
      state.sendPagando = !state.sendPagando;
    },
  },
  getters: {
    getTypeNotifications: (state) => {
      return state.typeNotifications;
    }
  },
};