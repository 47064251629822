<template>
  <div
    class="p-dialog-mask p-component-overlay p-component-overlay-enter"
    style="z-index: 2202"
  >
    <div
      class="overflow-auto surface-overlay px-2 md:px-3 py-3 p-dialog p-component background-print-ticket"
      aria-modal="true"
      pv_id_3=""
      style="width: 1000px; z-index: 2203"
    >
      <div class="card m-0 p-fluid">
        <div class="grid">
          <div
            id="ticket_preview"
            class="ticket-print-preview card col-12 xl:col-6 lg:col-6 md:col-6 p-2 m-2 md:m-0 my-0"
          >
            <div class="grid flex justify-content-between">
              <div class="col-12 md:col-6 lg:col-6 text-center p-0 pt-2 md:p-2">
                <img src="layout/images/logo-pagando.svg" class="image-ldo" />
              </div>
              <div
                class="col-12 md:col-6 lg:col-6 text-center md:text-right p-0 pt-2 md:p-2"
              >
                <p class="m-0 md-m-1">Agencia: <b>Pagando</b></p>
                <p class="m-0 md-m-1">Taquilla: <b>Online</b></p>
              </div>
            </div>
            <hr />
            <div class="grid flex justify-content-between">
              <div class="col-7 m-0">
                Nombre:
                <span
                  ><b
                    >{{ activeClient.first_name }}
                    {{ activeClient.last_name }}</b
                  ></span
                >
              </div>
              <div class="col-5 m-0 text-right">
                Fecha:
                <span>
                  <b>
                    {{
                      currentPlayData.ticket
                        ? formatDate(
                            currentPlayData.ticket.created_at,
                            "DD-MM-YYYY"
                          )
                        : ""
                    }}
                  </b>
                </span>
              </div>
            </div>
            <div class="grid flex justify-content-between">
              <div class="col-6 m-0">
                Cédula:
                <span
                  ><b
                    >{{ activeClient.dni_prefix }}{{ activeClient.dni }}</b
                  ></span
                >
              </div>
              <div class="col-6 m-0 text-right">
                Hora:
                <span>
                  <b>
                    {{
                      currentPlayData.ticket
                        ? formatDate(currentPlayData.ticket.created_at, "LTS")
                        : ""
                    }}
                  </b>
                </span>
              </div>
            </div>
            <div class="grid flex justify-content-between">
              <div class="col-5 m-0">
                Teléfono:
                <span>
                  <b>
                    {{ phonePrimary }}
                  </b>
                </span>
              </div>
              <div class="col-7 m-0 text-right">
                N° Boleto:
                <span
                  ><b>{{
                    currentPlayData.ticket
                      ? `${currentPlayData.ticket.serial}`
                      : ""
                  }}</b></span
                >
              </div>
            </div>
            <br />
            <div class="grid ticket-detail-container m-0 p-0">
              <DataTable
                class="grid overflow-y-auto surface-overlay p-0 m-0"
                :value="currentPlayData.plays"
                :scrollable="true"
                scrollHeight="flex"
                :showGridlines="false"
                rowHover
                :loading="is_processing"
                rowGroupMode="subheader"
                groupRowsBy="category_code"
                sortMode="single"
                sortField="category_code"
                :sortOrder="1"
                :expandableRowGroups="false"
                style="width: 100%"
              >
                <template #loading>Procesando. Por favor Espere... </template>
                <template #groupheader="slotProps">
                  <div class="grid w-full">
                    <div class="col-5 flex align-items-center">
                      <img
                        v-if="
                          activeGameList &&
                          activeGameList.find(
                            (e) => e.id === slotProps.data.fk_game
                          )
                        "
                        :src="
                          activeGameList.find(
                            (e) => e.id === slotProps.data.fk_game
                          ).logo
                        "
                        style="width: auto; height: 35px"
                      />
                    </div>
                    <div class="col-7">
                      <div class="flex flex-column m-0 p-0">
                        <div class="m-1 mr-0 text-right">
                          Fecha:
                          <span
                            ><b>{{
                              formatDate(
                                slotProps.data.event_datetime,
                                "DD-MM-YYYY"
                              )
                            }}</b></span
                          >
                        </div>
                        <div class="m-1 mr-0 text-right">
                          Hora:
                          <span
                            ><b>{{
                              formatDate(slotProps.data.event_datetime, "LT")
                            }}</b></span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template #empty></template>
                <div>
                  <Column class="col-1 text-base font-bold" header="ITEM">
                    <template #body="slotProps">
                      {{ slotProps.index + 1 }}
                    </template>
                  </Column>
                </div>
                <div>
                  <Column
                    class="col-7 text-base font-bold"
                    field="name"
                    header="PRODUCTO/SERVICIO"
                  ></Column>
                </div>
                <div>
                  <Column
                    class="col-4 flex justify-content-end text-base font-bold"
                    field="price"
                    header="MONTO BUSD"
                    :headerStyle="{
                      textAlign: 'right',
                      minWidth: 'fit-content',
                    }"
                  >
                    <template #body="slotProps">
                      {{ Number(slotProps.data.price).toFixed(2) }}
                    </template>
                  </Column>
                </div>
              </DataTable>
            </div>
            <hr class="mt-0 mb-3" />
            <div class="grid flex justify-content-between">
              <div class="col-8 text-xl font-bold">SUB-TOTAL</div>
              <div class="col-2 text-xl font-bold text-right">BUSD</div>
              <div class="col-2 text-xl font-bold text-right">
                <b>{{
                  currentPlayData &&
                  currentPlayData.ticket &&
                  !isNaN(currentPlayData.ticket.sub_total)
                    ? Number(currentPlayData.ticket.sub_total).toFixed(2)
                    : Number(0).toFixed(2)
                }}</b>
              </div>
            </div>
            <div class="grid flex justify-content-between">
              <div class="col-8 text-xl font-bold">IMPUESTO</div>
              <div class="col-2 text-xl font-bold text-right">0.00%</div>
              <div class="col-2 text-xl font-bold text-right">0.00</div>
            </div>
            <hr />
            <div class="grid flex justify-content-between">
              <div class="col-8 text-xl font-bold">TOTAL APUESTA</div>
              <div class="col-2 text-xl font-bold text-right">BUSD</div>
              <div class="col-2 text-xl font-bold text-right">
                <b>{{
                  currentPlayData &&
                  currentPlayData.ticket &&
                  !isNaN(currentPlayData.ticket.total)
                    ? Number(currentPlayData.ticket.total).toFixed(2)
                    : Number(0).toFixed(2)
                }}</b>
              </div>
            </div>
          </div>

          <div class="col-12 xl:col-6 md:col-6 text-center">
            <p
              class="text-3xl md:text-2xl lg:text-auto title-color font-bold mt-2 mb-6"
            >
              ¿Cómo desea obtener su ticket?
            </p>
            <!-- Imprimir & PDF -->
            <div
              class="xl:col-12 md:col-12 px-0 py-0 flex justify-content-center align-items-center"
              v-if="!notificationRequiresPaymentApproval"
            >
              <ButtonPrintTicket :serial="currentPlayData.ticket.serial" ref="button_print" />
            </div>
            <!-- Whatsapp y Telegram -->
            <div class="xl:col-12 md:col-12 py-0">
              <button
                type="button"
                class="button-get-ticket m-1 whatsapp relative"
                @click="sendNotification('whatsapp')"
                :disabled="notificationOptions['whatsapp'].is_processing"
                v-if="phoneWhatsapp.length > 0"
              >
                <div class="flex">
                  <div class="col-4 p-1">
                    <i
                      class="pi pi-check-circle"
                      v-if="
                        notificationOptions['whatsapp'].already_sent &&
                        !notificationOptions['whatsapp'].is_processing
                      "
                      style="top: 5px; right: 5px; position: absolute"
                    ></i>
                    <i
                      class="pi pi-spin pi-spinner"
                      v-if="notificationOptions['whatsapp'].is_processing"
                      style="top: 5px; right: 5px; position: absolute"
                    ></i>
                    <i class="pi pi-whatsapp" style="font-size: 4rem"></i>
                  </div>
                  <div class="col-8 p-1">
                    <p
                      class="title-get-ticket text-left text-3xl md:text-2xl font-bold m-0"
                    >
                      Whatsapp
                    </p>
                    <p class="font-bold text-left">
                      {{ phoneWhatsapp }}
                    </p>
                  </div>
                </div>
              </button>
              <button
                type="button"
                class="button-get-ticket m-1 telegram relative"
                @click="sendNotification('telegram')"
                :disabled="notificationOptions['telegram'].is_processing"
                v-if="phoneTelegram.length > 0"
              >
                <div class="flex">
                  <div class="col-4 p-1">
                    <i
                      class="pi pi-check-circle"
                      v-if="
                        notificationOptions['telegram'].already_sent &&
                        !notificationOptions['telegram'].is_processing
                      "
                      style="top: 5px; right: 5px; position: absolute"
                    ></i>
                    <i
                      class="pi pi-spin pi-spinner"
                      v-if="notificationOptions['telegram'].is_processing"
                      style="top: 5px; right: 5px; position: absolute"
                    ></i>
                    <i class="pi pi-telegram" style="font-size: 4rem"></i>
                  </div>
                  <div class="col-8 p-1">
                    <p
                      class="title-get-ticket text-left text-3xl md:text-2xl font-bold m-0"
                    >
                      Telegram
                    </p>
                    <p class="font-bold text-left">
                      {{ phoneTelegram }}
                    </p>
                  </div>
                </div>
              </button>
            </div>
            <!-- SMS & Email -->
            <div class="xl:col-12 md:col-12 px-0 py-0">
              <button
                type="button"
                class="button-get-ticket m-1 email relative"
                @click="sendNotification('mail')"
                :disabled="notificationOptions['mail'].is_processing"
                v-if="activeClient.fk_contact.email"
              >
                <div class="flex">
                  <div class="col-4 p-1">
                    <i
                      class="pi pi-check-circle"
                      v-if="
                        notificationOptions['mail'].already_sent &&
                        !notificationOptions['mail'].is_processing
                      "
                      style="top: 5px; right: 5px; position: absolute"
                    >
                    </i>
                    <i
                      class="pi pi-spin pi-spinner"
                      v-if="notificationOptions['mail'].is_processing"
                      style="top: 5px; right: 5px; position: absolute"
                    >
                    </i>
                    <i class="pi pi-envelope" style="font-size: 4rem"></i>
                  </div>
                  <div class="col-8 p-1">
                    <p
                      class="title-get-ticket text-left text-3xl md:text-2xl font-bold m-0"
                    >
                      Email
                    </p>
                    <p class="font-bold text-left">
                      {{
                        activeClient.fk_contact.email
                          ? `${activeClient.fk_contact.email}`
                          : ""
                      }}
                    </p>
                  </div>
                </div>
              </button>
              <button
                type="button"
                @click="sendNotification('sms')"
                :disabled="notificationOptions['sms'].is_processing"
                class="button-get-ticket m-1 sms relative"
                v-if="
                  !notificationRequiresPaymentApproval ||
                  phonePrimary.length > 0
                "
              >
                <div class="flex">
                  <div class="col-4 p-1">
                    <i
                      class="pi pi-check-circle"
                      v-if="
                        notificationOptions['sms'].already_sent &&
                        !notificationOptions['sms'].is_processing
                      "
                      style="top: 5px; right: 5px; position: absolute"
                    ></i>
                    <i
                      class="pi pi-spin pi-spinner"
                      v-if="notificationOptions['sms'].is_processing"
                      style="top: 5px; right: 5px; position: absolute"
                    ></i>
                    <i class="pi pi-comments" style="font-size: 4rem"></i>
                  </div>
                  <div class="col-8 p-1">
                    <p
                      class="title-get-ticket text-left text-3xl md:text-2xl font-bold m-0"
                    >
                      SMS
                    </p>
                    <p class="font-bold text-left">
                      {{ phonePrimary }}
                    </p>
                  </div>
                </div>
              </button>
              
            </div>
            
            <div class="xl:col-12 md:col-12 text-center my-6 px-0 py-0">
              <!-- <button type="button" class="button-anular text-3xl font-bold m-1" >
                  <p class="m-2">Anular </p>
                </button> -->
              <button
                type="button"
                @click="closePrintModal()"
                class="button-listo text-3xl font-bold m-1"
              >
                <p class="m-2">Listo</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template> 

<script>
import DataTable from "primevue/datatable";
import { mapGetters, mapMutations, mapState } from "vuex";
import moment from "moment";
import ButtonPrintTicket from "../buttons/ButtonPrintTicket.vue";

export default {
  components: {
    DataTable,
    ButtonPrintTicket
  },
  data() {
    return {
      notificationOptions: {
        mail: {
          is_processing: false,
          already_sent: false,
        },
        whatsapp: {
          is_processing: false,
          already_sent: false,
        },
        sms: {
          is_processing: false,
          already_sent: false,
        },
        telegram: {
          is_processing: false,
          already_sent: false,
        },
      },
      scroll_visible: true,
      notificationRequiresPaymentApproval: false,
    };
  },
  mounted() {
    this.validateNotificationRequiresPaymentApproval();
    window.removeEventListener("keydown", this.handleKeyPress);
    window.addEventListener("keydown", this.handleKeyPress);
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.handleKeyPress);
  },
  methods: {
    ...mapMutations("ticketoffice", [
      "clearCurrentPlayData",
      "setActiveClient",
      "saveTicketofficeSession",
      "setShowPrint",
      "setShowConsultClient",
    ]),
    closePrintModal() {
      this.setShowPrint(false);
      this.setActiveClient({});
      this.clearCurrentPlayData();
      this.saveTicketofficeSession();
      this.setShowConsultClient(true);
    },
    sendNotification(key) {
      let severity = "success";
      let type_notification_id = null;

      if (this.notificationRequiresPaymentApproval) {
        if (this.notificationOptions[key].already_sent) {
          return;
        }

        severity = "warn";
      }

      const typeNotification = this.getTypeNotifications.filter(
        (item) => item.name === key
      );

      if (typeNotification.length === 0) {
        this.$store.dispatch("changeSystemMessage", {
          severity: "error",
          summary: "Error",
          detail: "No existe el tipo de notificación.",
          life: 4000,
        });
        return;
      }

      type_notification_id = typeNotification[0].id;

      this.notificationOptions[key].is_processing = true;
      let isFunctionBlocked =  false; 
      if (type_notification_id === 4) {
        isFunctionBlocked = true;
        setTimeout(() => {
          isFunctionBlocked = false;
          this.notificationOptions[key].is_processing = false
        }, 8000);
      }
      if(isFunctionBlocked == false){ 
        this.$store
          .dispatch("ticketoffice/sendPlayOrderTicketNotification", {
            serial: this.currentPlayData.ticket.serial,
            type_notification_id,
          })
          .then((resp) => {
            this.notificationOptions[key].already_sent = true;
            this.$store.dispatch("changeSystemMessage", {
              severity,
              detail: resp.data.message,
              life: 4000,
            }); 
          })
          .catch((err) => {
            //console.log(err)
            if (err.response)
              this.$store.dispatch("changeSystemMessage", {
                severity: "error",
                summary: "Error",
                detail: "Notificación fallida.",
                life: 4000,
              });
          })
          .finally(() => {
            this.notificationOptions[key].is_processing = false;
          });
      }
    },
    validateNotificationRequiresPaymentApproval() {
      for (const item in this.payOptions) {
        const payOptionItem = this.payOptions[item];

        for (const moneda in this.monedaOptions) {
          const monedaOptionItem = this.monedaOptions[moneda];
          const paysMonedaOptionByCodes = this.currentPlayData.pays.filter(
            (o) =>
              o.currency_id == monedaOptionItem.code &&
              payOptionItem.monedaOptionByCodes.includes(o.currency_id) &&
              payOptionItem.options.some(
                (option) =>
                  option.id === o.fk_payment_method_id &&
                  option.fk_currency === o.currency_id
              )
          ).length;

          if (
            paysMonedaOptionByCodes > 0 &&
            payOptionItem.notificationRequiresPaymentApproval
          ) {
            this.notificationRequiresPaymentApproval =
              payOptionItem.notificationRequiresPaymentApproval;
            break;
          }
        }

        if (this.notificationRequiresPaymentApproval) break;
      }
    },
    formatDate(date, format, utcToLocal = false) {
      return utcToLocal
        ? moment.utc(date, ["DD/MM/YYYY hh:mm:ss"]).local().format(format)
        : moment(date, ["DD/MM/YYYY hh:mm:ss"]).format(format);
    },
    handleKeyPress(e) {
      if (e.code && e.code === "F2") {
        this.$refs.button_print.printTicket();
      }
    },
  },
  computed: {
    ...mapGetters("ticketoffice", [
      "activeGameList",
      "activeClient",
      "selectedGame",
      "selectedLottery",
      "currentPlayData",
      "phonePrimary",
      "phoneWhatsapp",
      "phoneTelegram",
    ]),
    ...mapGetters("notification", ["getTypeNotifications"]),
    ...mapState("ticketoffice", [
      "is_processing",
      "payOptions",
      "monedaOptions",
      "showPrint",
    ]),
  },
};
</script>

<style scoped>
:deep(.p-rowgroup-header) {
  background-color: #f5f5f5 !important;
}
:deep(.p-datatable-flex-scrollable .p-datatable-wrapper) {
  width: 100%;
  min-width: 100%;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}
:deep(
    .p-datatable.p-datatable-scrollable
      > .p-datatable-wrapper
      > .p-datatable-table
      > .p-datatable-thead
  ) {
  width: 100%;
  min-width: 100%;
}
:deep(
    .p-datatable-scrollable .p-datatable-thead > tr,
    .p-datatable.p-datatable-scrollable
      > .p-datatable-wrapper
      > .p-datatable-table
      > .p-datatable-thead
  ) {
  width: 100%;
  min-width: 100%;
}
:deep(.p-datatable-scrollable .p-datatable-tbody) {
  width: 100%;
  min-width: 100%;
}
:deep(.p-datatable-scrollable .p-datatable-tbody > tr) {
  width: 100%;
  min-width: 100%;
}
.p-splitbutton.print {
  justify-content: space-between;
}
:deep(.p-splitbutton.print .p-button.p-splitbutton-menubutton) {
  background-color: transparent !important;
  border-left: #ccc solid 1px;
  color: #000;
}
:deep(.p-splitbutton.print:hover .p-button.p-splitbutton-menubutton) {
  color: #fff;
}
#hero {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    radial-gradient(77.36% 256.97% at 77.36% 57.52%, #eeefaf 0%, #c3e3fa 100%);
  height: 700px !important;
  width: 100%;
  overflow: hidden;
}
.ticket-detail-container {
  height: 300px;
  width: 100%;
  margin: 0px;
  padding: 0px;
}
.background-print-ticket {
  padding: 1rem;
  border-radius: 1rem;
}

.image-ldo {
  width: 16rem;
}
.image-kriptorifa {
  width: 9rem;
}
.title-color {
  color: #cc195a;
}
.button-get-ticket {
  width: 20rem;
  height: 6rem;
  border-radius: 1rem;
  border: 0rem;
  cursor: pointer;
  background-color: buttonface;
}
.button-get-ticket:disabled,
.button-get-ticket[disabled] {
  pointer-events: none;
}
.get-ticket {
  color: #7d7d7d;
}
.print:hover {
  background: #000;
  color: #fff;
  box-shadow: inset 4px 4px 4px rgba(0, 0, 0, 0.25);
}
.pdf:hover {
  background: #cc195a;
  color: #fff;
  box-shadow: inset 4px 4px 4px rgba(0, 0, 0, 0.25);
}
.sms:hover {
  background: #196fe1;
  color: #fff;
  box-shadow: inset 4px 4px 4px rgba(0, 0, 0, 0.25);
}
.email:hover {
  background: #323232;
  color: #fff;
  box-shadow: inset 4px 4px 4px rgba(0, 0, 0, 0.25);
}
.whatsapp:hover {
  background: #00e676;
  color: #fff;
  box-shadow: inset 4px 4px 4px rgba(0, 0, 0, 0.25);
}
.telegram:hover {
  background: #25a0e1;
  color: #fff;
  box-shadow: inset 4px 4px 4px rgba(0, 0, 0, 0.25);
}
.button-anular {
  background: #cc195a;
  color: #fff;
  width: 12rem;
  border-radius: 0.5rem;
  border: 0rem;
}
.button-listo {
  background: #46af3d;
  color: #fff;
  width: 20rem;
  border-radius: 0.5rem;
  border: 0rem;
  cursor: pointer;
}
.button-anular:hover {
  background: #cc195a;
  color: #fff;
  box-shadow: inset 4px 4px 4px rgba(0, 0, 0, 0.25);
}
.button-listo:hover {
  background: #46af3d;
  color: #fff;
  box-shadow: inset 4px 4px 4px rgba(0, 0, 0, 0.25);
}
.get-ticket:hover {
  color: #fff;
}

@media screen and (max-width: 768px) {
  .button-get-ticket {
    width: 20rem;
    height: 6rem;
    border-radius: 1rem;
    border: 0rem;
  }
  .button-anular {
    width: 10rem;
  }
  .button-listo {
    width: 20rem;
  }
  .title-color {
    color: #cc195a;
    margin-top: 4rem !important;
  }
}

text {
  width: 100%;
  font-size: 50px;
  fill: #000;
  font-weight: bold;
}
</style>
