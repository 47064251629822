import { createRouter, createWebHistory } from "vue-router";

import { routeProduction, beforeEachProduction } from "./routes/routeProduction";
import { routeDevelopment, beforeEachDevelopment } from "./routes/routeDevelopment";

let routes = null;
let beforeEach = null;

if (process.env.VUE_APP_API_PRODUCTION === 'true') {
    routes = routeProduction;
    beforeEach = beforeEachProduction;
} else {
    routes = routeDevelopment;
    beforeEach = beforeEachDevelopment;
}

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(beforeEach);
export default router;
