<template>
  <SplitButton
    type="button"
    class="button-get-ticket m-1 print"
    :model="print_options"
    :disabled="is_printing"
    :loading="is_printing"
  >
    <div class="flex w-full align-items-center" @click="printTicket()">
      <div class="col-5 p-1">
        <i
          class="pi pi-spin pi-spinner"
          v-if="is_printing"
          style="top: 5px; right: 5px; position: absolute"
        ></i>
        <i class="pi pi-print" style="font-size: 4rem"></i>
      </div>
      <div class="col-7 p-1">
        <p
          class="title-get-ticket text-left text-3xl md:text-xl font-bold m-0 flex flex-column"
        >
          <span>Impresora</span
          ><span class="text-sm">Formato {{ print_format }}</span>
        </p>
      </div>
    </div>
  </SplitButton>
</template>
<script>
import { fromString } from "html-to-text";
import printJS from "print-js";
import HTTP from "@/service/http";

export default {
  props: {
    serial: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      is_printing: false,
      json_to_print: null,
      print_format: localStorage.getItem("print_format") || "pos",
      print_options: [
        {
          label: "Formato de impresión normal",
          icon: "pi pi-cog",
          command: () => {
            this.print_format = "normal";
            localStorage.setItem("print_format", "normal");
          },
        },
        {
          label: "Formato de impresión Pos",
          icon: "pi pi-cog",
          command: () => {
            this.print_format = "pos";
            localStorage.setItem("print_format", "pos");
          },
        },
      ],
    };
  },
  created() {
    this.getJsonData();
  },
  methods: {
    getJsonData(is_printing = false) {
      this.is_printing = is_printing;
      HTTP(true, 7000)
        .get(`/payorders/jsoninvoices/${this.serial}`)
        .then((resp) => {
          console.log(resp);
          if (resp.data.status === "SUCCESS") {
            this.json_to_print = resp.data.data;
          } else {
            this.json_to_print = null;
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response)
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: err.response.data.message,
              life: 7000,
            });
          this.json_to_print = null;
        })
        .finally(() => {
          this.is_printing = false;
        });
    },
    printTicketPOS() {
      if(!this.json_to_print) return;
      
      let ticket_text = `
          <table cellpadding="0" cellspacing="0" border="0" style="margin: 0px; padding: 0px; font-size: 9px; min-width: 92vw; width: 97%;">
            <tr>
              <td>
                Ag: ${this.json_to_print.meta.agency}
              </td>
              <td style="text-align: right">Tq: ${this.json_to_print.meta.ticketoffice}</td>
            </tr>
            <tr>
              <td>
                Nombre: ${this.json_to_print.person_name}
              </td>
              <td style="text-align: right">${this.json_to_print.date}</td>
            </tr>
            <tr>
              <td>
                C.I: ${this.json_to_print.dni}
              </td>
              <td style="text-align: right">${this.json_to_print.hour}</td>
            </tr>
            <tr>
              <td>
                Tlf: ${this.json_to_print.phone_number}
              </td>
              <td style="text-align: right">Nº: ${this.json_to_print.serial}</td>
            </tr>
            <tr><td colspan="2"><hr></td></tr>
          `;
      let ticket_detail = "";
      this.json_to_print.pay_order_products.forEach((play) => {
        ticket_detail += `<tr>
              <td style="font-weight: 600">${play.url}</td>
              <td style="text-align: right; font-weight: 600"> ${play.event_datetime.date} ${play.event_datetime.time}</td>
            </tr>`;
        play.products.forEach((e) => {
          ticket_detail += `<tr>
                <td>${e.alias}</td>
                <td style="text-align: right">${this.formatNumberToLocale(
                  e.price
                )} BUSD  (${this.formatNumberToLocale(e.price_bs)} VES)</td>
              </tr>`;
        });
      });
      ticket_detail += `<tr><td colspan="2"><hr></td></tr></table>
      <table cellpadding="0" cellspacing="0" border="0" style="margin: 0px; padding: 0px; font-size: 9px; min-width: 92vw; width: 97%; margin-top: -3mm;">
            <tr>
              <td>Sub-Total</td>
              <td style="text-align: right">${this.formatNumberToLocale(
                this.json_to_print.sub_total
              )} BUSD  (${this.formatNumberToLocale(
        this.json_to_print.sub_total_bs
      )} VES)</td>
            </tr>
            <tr>
              <td>Impuesto</td>
              <td style="text-align: right">0.00%</td>
            </tr>
            <tr>
              <td style="font-weight: 600">Total</td>
              <td style="font-weight: 600; text-align: right">${this.formatNumberToLocale(
                this.json_to_print.total
              )} BUSD  (${this.formatNumberToLocale(
        this.json_to_print.total_bs
      )} VES)</td>
            </tr>
      `;
      ticket_text += `${ticket_detail}</table>`;
      this.printHtmlToPlainText(ticket_text);
    },
    async printTicket() {
      if (this.print_format === "pos") {
        if (this.json_to_print === null) await this.getJsonData(true);
        this.printTicketPOS();
      } else {
        setTimeout(() => {
          printJS({
            printable: `${process.env.VUE_APP_API_BASE_URL}/payorders/invoices/${this.serial}`,
            type: "pdf",
            style:
              "@page {size: portrait;} @media print {.ticket-print-preview, .grid, hr {width: 100% !important; max-width: 100%;} .ticket-detail-container, .card, .p-datatable-flex-scrollable, .p-datatable-flex-scrollable .p-datatable-wrapper {height: auto !important; width: 100%; overflow: hidden !important; } .card { box-shadow: unset !important;} .p-column-header-content, .p-column-title {width: 100% !important;}}",
            documentTitle: "",
            header: "",
            onLoadingStart: () => {
              this.is_printing = true;
            },
            onLoadingEnd: () => {
              this.is_printing = false;
            },
          });
        }, 50);
      }
    },
    printHtmlToPlainText(html) {
      // Convertir HTML a texto sin formato
      const text = fromString(html, {
        wordwrap: false,
        ignoreHref: true,
        ignoreImage: true,
        selectors: [
          { selector: "table", format: "dataTable" },
          {
            selector: "#ticket_preview",
            format: "dataTable",
            maxColumnWidth: 80,
          },
        ],
      });
      console.log(text);
      // Crear un elemento iframe para contener el texto sin formato
      const printWindow = document.createElement("iframe");
      printWindow.style.display = "none";
      document.body.appendChild(printWindow);

      // Agregar el texto sin formato al iframe
      printWindow.contentDocument.write(
        "<pre style='margin: 0; padding: 0; font-size: 4px'>" + html + "</pre>"
      );
      printWindow.contentDocument.close();

      // Imprimir y eliminar el iframe
      printWindow.contentWindow.print();
      printWindow.parentNode.removeChild(printWindow);
    },
    formatNumberToLocale(number, locale = "es-VE") {
      return Number(number).toLocaleString(locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
  },
};
</script>
