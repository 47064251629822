import HTTP from "@/service/http";

export default {
  namespaced: true,
  state: {
    paymentMethods: [],
  },
  actions: {
    getListPaymentMethods({ commit }, data) {
      return new Promise((resolve, reject) => {
        HTTP(true)
          .get("/paymentmethods?limit=100", data)
          .then((resp) => {
            commit("setPaymentMethods", resp.data.data);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },
  mutations: {
    setPaymentMethods: (state, data) => {
      state.paymentMethods = data;
    },
  },
  getters: {
    getPaymentMethods: (state) => {
      return state.paymentMethods;
    }
  },
};