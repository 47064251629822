<template>
  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar @menu-toggle="onMenuToggle" />
    <div class="menu-lotomax layout-sidebar" @click="onSidebarClick" v-if="!['/ticketoffice-online', '/ticketoffice'].includes($route.path)">
      <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
    </div>

    <div class="layout-main-container">
      <div class="layout-main">
        <router-view />
      </div>
      <AppFooter />
    </div>
    <transition name="layout-mask">
      <div
        class="layout-mask p-component-overlay"
        v-if="mobileMenuActive"
      ></div>
    </transition>
    <Sidebar v-model:visible="show_sidebar" position="bottom" :blockScroll="true" style="height: calc(100% - 173px)">
      <component :is="currentSidebarComponent"></component>
    </Sidebar>
  </div>
</template>

<script>
import AppTopBar from "./AppTopbar.vue";
import AppMenu from "./AppMenu.vue";
import AppFooter from "./AppFooter.vue";
import Sidebar from "primevue/sidebar";
import EventBus from "./AppEventBus";
//import { defineAsyncComponent, markRaw } from "vue";
export default {
  emits: ["change-theme"],
  data() {
    return {
      layoutMode: 'overlay', //overlay or static
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      currentSidebarComponent: null,
    };
  },
  mounted() {
    EventBus.on("show_sidebar", async (data) => {
      if (data && typeof data === "object") {
        if (data.component) {
          this.currentSidebarComponent = data.component;
          this.show_sidebar = true;
        }
      }
    });
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    },
  },
  methods: {
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === "overlay") {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === "static") {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    addClass(element, className) {
      if (element.classList) element.classList.add(className);
      else element.className += " " + className;
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className);
      else
        element.className = element.className.replace(
          new RegExp(
            "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
            "gi"
          ),
          " "
        );
    },
    isDesktop() {
      return window.innerWidth >= 992;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === "static") return !this.staticMenuInactive;
        else if (this.layoutMode === "overlay") return this.overlayMenuActive;
      }

      return true;
    },
    changeTheme(event) {
      this.$emit("change-theme", event);
    },
  },
  computed: {
    containerClass() {
      return [
        "tablero-background layout-wrapper",
        {
          "layout-overlay": this.layoutMode === "overlay",
          "layout-static": this.layoutMode === "static",
          "layout-static-sidebar-inactive":
            this.staticMenuInactive && this.layoutMode === "static",
          "layout-overlay-sidebar-active":
            this.overlayMenuActive && this.layoutMode === "overlay",
          "layout-mobile-sidebar-active": this.mobileMenuActive,
          "p-input-filled": this.$primevue.config.inputStyle === "filled",
          "p-ripple-disabled": this.$primevue.config.ripple === false,
        },
      ];
    },
    logo() {
      return this.$appState.darkTheme
        ? "layout/images/logo-pagando.svg"
        : "layout/images/logo-pagando.svg";
    },
    menu() {
      return this.$store.getters["auth/userMenu"];
    },
    show_sidebar: {
      get() {
        return this.$store.getters["getShowSidebar"];
      },
      set(value) {
        this.$store.commit("setShowSidebar", value);
      },
    }
  },
  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, "body-overflow-hidden");
    else this.removeClass(document.body, "body-overflow-hidden");
  },
  beforeUnmount() {
    EventBus.off("show_sidebar");
  },
  components: {
    AppTopBar: AppTopBar,
    AppMenu: AppMenu,
    AppFooter: AppFooter,
    Sidebar,
  },
};
</script>

<style lang="scss">
@import "./App.scss";
</style>
