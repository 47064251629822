<template>
  <div class="box-item-user-options-menu">
    <div>
      <ul class="p-0 text-center">
        <li>
          <div ref="receipt_button">
            <Button class="p-link" @click="toggleUserMenu">
              <i class="pi pi-bars icon-bars mt-3"></i>
            </Button>
          </div>
          <div
            v-show="showUserMenu"
            class="user-menu"
            ref="myDiv"
          >
            <div
              class="user-menu-avatar p-3"
              v-if="role"
            >
              <div class="grid">
                <div class="col-3">
                  <IconAvatar />
                </div>
                <div class="col-7 pl-3">
                  <span class="avatar-name mt-2">Hola {{ role }}</span>
                  <br />
                  <span class="avatar-user">{{ email }}</span>
                </div>
              </div>
            </div>
            <div class="user-menu-items pb-1 mt-2">
              <ul class="p-0">
                <li
                  v-for="(item, index) in userMenuItems.filter(e => !e.hide)"
                  :key="index"
                  class="mb-2"
                  :class="item.li"
                  @click="() => item.command ? item.command() : false"
                >
                  <div
                    class="grid p-1 pl-4"
                    :class="item.grid"
                  >
                    <div class="col-1 text-center">
                      <i v-if="item.icon" :class="item.icon"></i>
                      <component v-else :is="item.iconComponent" :class="item.iconClass"></component>
                    </div>
                    <div class="col-11 text-left">
                      <p
                        class="pl-1 item-text"
                        :class="item.labelClass"
                      >
                        {{ item.label }}
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <!-- <IconNotification /> -->

    
    <a 
    href="https://api.whatsapp.com/send?phone=584147242636" 
    target="_blank" 
    margin-top="35px"
    display="block"
    class="h iconSupport">
      <IconSupport/>
    </a>

    <ConsultClientPayOrders />
    <balance-withdrawal />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import IconAvatar from "@/components/svg/icons/IconAvatar.vue";
import IconNotification from "@/components/svg/icons/IconNotification.vue";
import IconSupport from "@/components/svg/icons/IconSupport.vue";
import IconHelp from "@/components/svg/icons/IconHelp.vue";
import IconProfile from "@/components/svg/icons/IconProfile.vue";
import IconSetting from "@/components/svg/icons/IconSetting.vue";
import IconReport from "@/components/svg/icons/IconReport.vue";
import IconSignOff from "@/components/svg/icons/IconSignOff.vue";
import IconSearch from "@/components/svg/icons/IconSearch.vue";
import IconTicket from "@/components/svg/icons/IconTicket.vue";

import ConsultClientPayOrders from "@/components/ticketoffice/modals/ConsultClientPayOrders.vue";
import menuGuest from "@/menus/invitado";
import BalanceWithdrawal from '../ticketoffice/modals/BalanceWithdrawal.vue';
import EventBus from "@/AppEventBus";

export default {
  components: {
    IconAvatar,
    IconNotification,
    IconSupport,
    IconHelp,
    IconProfile,
    IconSetting,
    IconReport,
    IconSignOff,
    IconSearch,
    IconTicket,
    ConsultClientPayOrders,
    BalanceWithdrawal,
  },
  data() {
    return {
      showUserMenu: false,
      userMenuItems: [
        {
          label: "Inicio",
          icon: "pi pi-fw pi-home icon-color",
          command: () => {
            this.toggleUserMenu();
            this.$router.push("/");
          },
        },
        {
          label: "Perfil",
          iconClass: "pt-1",
          iconComponent: "IconProfile",
          hide: this.$store.getters['auth/role'] !== 'agencia',
          command: () => {
            this.toggleUserMenu();
            this.$router.push("/profile");
          },
        },
        // {
        //   label: "Reportes",
        //   iconClass: "pt-1",
        //   iconComponent: "IconReport",
        // },
        // {
        //   label: "Ajustes",
        //   iconClass: "pt-1",
        //   iconComponent: "IconSetting",
        // },
        // {
        //   label: "Ayuda",
        //   grid: "mt-5 mb-2",
        //   iconClass: "pt-1",
        //   iconComponent: "IconHelp"
        // },
        {
          label: "Cerrar sesión",
          labelClass: "text-white font-bold mt-1",
          grid: "mt-5 mb-2 box-color p-2",
          li: "h-0 ml-2 mr-1",
          iconClass: "pt-1",
          iconComponent: "IconSignOff",
          command: () => {
            this.toggleUserMenu();
            this.$router.push("/logout");
          },
        },
      ],
    };
  },
  mounted() {
    this.addItemsMenu();
    document.addEventListener("click", this.handleClickOutside);
  },
  methods: {
    toggleUserMenu() {
      this.showUserMenu = !this.showUserMenu;
    },
    handleClickOutside() {
      if (this.$refs.myDiv && !this.$refs.myDiv.contains(event.target) && !this.$refs.receipt_button.contains(event.target) ) {
        this.showUserMenu = false;
      }
    },
    addItemsMenu() {
      if (!this.role) {
        menuGuest.menu.forEach(item => {
            if (item.to) {
              item.command = () => {
                this.toggleUserMenu();
                this.$router.push(item.to);
              };
            }
        });

        this.userMenuItems = menuGuest.menu;
        return;
      }

      import(`../../menus/${this.role}.js`)
        .then((m) => {
          m.default.menu.forEach((item, index) => {
            if (item.to) {
              item.command = () => {
                this.toggleUserMenu();
                this.$router.push(item.to);
              };
            }

            if (item.dispatch) {
              item.command = () => {
                this.toggleUserMenu();
                this.$store.dispatch(item.dispatch);
              };
            }

            if (item.showsidebar) {
              item.command = () => {
                this.toggleUserMenu();
                EventBus.emit("show_sidebar", item.showsidebar);
              };
            }

            this.userMenuItems.splice(index + 2, 0, item);
          });
        })
        .catch((err) => {
          console.log("Error loading user menu: ", err);
        });
    },
  },
  computed: {
    ...mapGetters("auth", ["role", "email"]),
  },
};
</script>

<style scoped>
ul {
  list-style: none;
}

.box-item-user-options-menu {
  width: 3.5em;
  height: 100%;
  background: #FFF;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}

.icon-color {
  color: #CC195A;
}

.box-color {
  background: #CC195A;
  color: #FFF;
}

.h-0 {
  height: 0em;
}

.icon {
  width: 1.5625em;
  display: block;
  margin: 1.2em auto;
  cursor: pointer;
}

.icon-bars {
  color: #CC195A;
  font-size: 1.8em;
  text-align: center;
  margin: 0 auto;
  display: block;
  cursor: pointer;
}

.icon-support, .icon-notification {
  fill: #CC195A;
}

.icon-support:hover,
.icon-notification:hover,
icon-bars:hover {
  fill: #8C113E;
}

.p-button:hover, .p-button:focus {
  background: #FFF;
}

.box-user-menu-items {
  left: 1360px !important;
  width: 16em !important;
}

.user-menu {
  position: absolute;
  background: #FFF;
  border-radius: 0.4em;
  width: 17em;
  right: 0.5em;
  box-shadow: 0px 0px 10px -4px #212121;
}

.avatar-name {
  color: #CC195A;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  display: inline-block;
  width: 100%;
  text-align: left;
}

.user-menu-avatar {
  background: #EDEDED;
}

.avatar-user {
  color: #404040;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: inline-block;
  width: 100%;
  text-align: left;
}

.user-menu-items ul li {
  cursor: pointer;
}

.user-menu-items ul li:hover {
  background: #EDEDED;
}

.item-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #404040;
}

li div .box-color:hover {
  background: #85092C;
}

@media screen and (max-width: 912px) {
  .box-item-user-options-menu {
    width: auto;
  }
}
</style>
