import HTTP from "@/service/http";

export default {
  namespaced: true,
  state: {
    marketers: [],
  },
  actions: {
    getMarketers({ commit }) {
      return new Promise((resolve, reject) => {
        HTTP()
          .get("/marketers")
          .then((resp) => {
            commit("setMarketersList", resp.data);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    registerMarketer(s, data) {
      return new Promise((resolve, reject) => {
        HTTP()
          .post("/marketers/partial", data)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getMarketerById(s, id) {
      return new Promise((resolve, reject) => {
        HTTP()
          .get("/marketers/" + id)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    verifyRegisterToken(s, token) {
      return new Promise((resolve, reject) => {
        HTTP(true)
          .get(`/marketers/partial?token=${token}`)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    registerMarketerFull(s, data) {
      return new Promise((resolve, reject) => {
        HTTP(true, 240000)
          .post("/marketers", data)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    sendNotification(s, data) {
      return new Promise((resolve, reject) => {
        HTTP()
          .post("/marketers/notify-review", data)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  mutations: {
    setMarketersList: (state, data) => {
      state.marketers = data.data;
    },
  },
  getters: {
    marketersList: (state) => {
      return state.marketers ? state.marketers : [];
    },
    requiredFiles: () => {
      return [
        {
          label: "Planilla de solicitud de registro",
          key: "fk_registration_request",
          tooltip:
            "Planilla de solicitud de registro de la Comercializadora, debidamente firmada y sellada.",
        },
        {
          label: "Acta o Documento Constitutivo",
          key: "fk_constitutive_document",
          tooltip:
            "Copia del Acta o Documento Constitutivo debidamente registrado de la comercializadora, cuyo objeto sea exclusivamente la explotación de actividades de envite y azar.",
        },
        {
          label: "Poder o Acta de Asamblea",
          key: "fk_power_registered_assembly",
          tooltip:
            "Copia del poder o Acta de Asamblea registrada donde conste la cualidad de representación y copia de la Cédula de Identidad.",
        },
        {
          label: "Relación de cada uno de los accionistas",
          key: "fk_shareholder_relationship",
          tooltip:
            "Relación de cada uno de los accionistas y del representante legal, en la cual se especifique documentos de identificación, nombres, apellidos, edad, Rif, nacionalidad, estado civil, domicilio, números de contacto y correos electrónicos personales, debidamente suscrita por el representante legal y con sello húmedo.",
        },
        {
          label: "RIF",
          key: "fk_rif",
          tooltip: "Copia del RIF vigente.",
        },
        {
          label: "Documento de Propiedad o del Contrato",
          key: "fk_leasing_contract",
          tooltip:
            "Copia del Documento de Propiedad o del Contrato de Arrendamiento del inmueble donde comercializará, debidamente autenticado.",
        },
        {
          label: "Licencia de Industria y Comercio",
          key: "fk_industry_license",
          tooltip: "Copia de Licencia de Industria y Comercio, vigente.",
        },
        {
          label: "Licencia emitida por la IOBPAS",
          key: "fk_license_iobpas",
          tooltip:
            "Copia de la Licencia emitida por la IOBPAS al comercializador.",
        },
        {
          label: "Relación de juegos comercializados",
          key: "fk_relationship_games_marketed",
          tooltip:
            "Relación de juegos comercializados con indicación de las sociedades mercantiles que fungen como operadoras, y sus respectivos contratos.",
        },
        {
          label: "Contrato suscrito con la sociedad mercantil",
          key: "fk_commercial_contrac",
          tooltip:
            "Copia de contrato suscrito con la sociedad mercantil que posee software de comercialización.",
        },
        {
          label: "Relación de Centros de Apuesta",
          key: "fk_betting_center_relationship",
          tooltip:
            "Relación de Centros de Apuesta con indicación de razón social, RIF, Domicilio Fiscal, No. de Licencia y fecha de vencimiento, No. de Registro ante la CONALOT y fecha de vencimiento, según sea el caso.",
        },
        {
          label: "Relación de Programas Informáticos",
          key: "fk_relationship_computer_programs",
          tooltip:
            "Relación de Programas Informáticos que utiliza para la Comercialización de juegos de loterías en sus diferentes modalidades.",
        },
        {
          label: "Estructura organizativa y manual organizacional",
          key: "fk_organizational_structure",
          tooltip:
            "Estructura organizativa y manual organizacional con plena adecuación a las normas sobre prevención, control y fiscalización de los delitos de legitimación de capitales y el financiamiento al terrorismo, en la actividad de juegos de Lotería.",
        },
        {
          label: "Declaración jurada de aceptación y dedicación.",
          key: "fk_sworn_declaration",
          tooltip:
            "Declaración jurada de aceptación y dedicación exclusiva del cargo de oficial de cumplimiento, síntesis",
        },
        {
          label: "Comprobante de pago",
          key: "fk_proof_payment",
          tooltip: "Comprobante de pago por concepto de tasa de registro.",
        },
        {
          label: "Comprobante de aporte a la CONALOT",
          key: "fk_conalot_contribution_voucher",
          tooltip:
            "Comprobante de aporte a la CONALOT derivado del trámite de registro.",
        },
      ];
    },
  },
};
