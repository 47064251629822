import HTTP from "@/service/http";
export default {
  namespaced: true,
  state: {
    user_data: {
      fk_contact: {},
      fk_direction: {},
    },
  },
  actions: {
    getUserProfile({ commit }) {
      return new Promise((resolve, reject) => {
        HTTP()
          .get("/users/profile/personal")
          .then((resp) => {
            commit("setUserData", resp.data.data);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    updateUserProfilePersonalData({ commit }, data) {
      return new Promise((resolve, reject) => {
        HTTP()
          .patch(`/users/profile/personal`, data)
          .then((resp) => {
            commit("setUserData", resp.data.data);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
    //   getSecurity({ commit }) {
    //     return new Promise((resolve, reject) => {
    //       HTTP()
    //         .get("/users/profile/seguridad")
    //         .then((resp) => {
    //           commit("setSecurity", resp.data.data);
    //           resolve(resp);
    //         })
    //         .catch((err) => {
    //           reject(err);
    //         });
    //     });
    //   },
  },

  mutations: {
    setUserData: (state, data) => {
      state.user_data = data;
    },
    // setSecurity: (state, data) => {
    //   state.security_data = data;
    // },
  },
  getters: {
    userData: (state) => {
      return state.user_data;
    },
    // security: (state) => {
    //   return state.security = data;
    // },
  },
};
