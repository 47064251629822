<template>
  <div class="p-dialog-mask p-component-overlay p-component-overlay-enter">
    <div class="surface-overlay credit-modal p-2">
      <div
        class="flex grid align-items-center justify-content-center title-margin"
      >
        <div class="col-1 flex justify-content-end">
          <i class="pi pi-wallet"></i>
        </div>
        <div class="col-10 m-0">
          <h4 class="mb-0">Wallet</h4>
        </div>
        <div class="col-1">
          <Button
            label=""
            icon="pi pi-times"
            class="cancel-buttom"
            @click="$emit('cancel')"
          />
        </div>
      </div>
      <hr />

      <div class="flex flex-row align-items-center justify-content-center mb-2">
        <div class="flex card-credit align-items-center justify-content-center">
          <div
            class="buttom-group align-items-center justify-content-center flex flex-row margin"
          >
            <div
              @click="selectTransactiontype('DepositCredit')"
              class="flex flex-column btn-credito align-items-center justify-content-center"
              :class="{
                'active-deposit-choice': transaction_type === 'DepositCredit',
              }"
            >
              Depositar
            </div>
            <div
              @click="selectTransactiontype('WithdrawalCredit')"
              class="flex flex-column btn-credito align-items-center justify-content-center"
              :class="{
                'active-deposit-choice':
                  transaction_type === 'WithdrawalCredit',
              }"
            >
              Retirar
            </div>
            <div
              @click="selectTransactiontype('TransferCredit')"
              class="flex flex-column btn-credito align-items-center justify-content-center"
              :class="{
                'active-deposit-choice': transaction_type === 'TransferCredit',
              }"
            >
              Enviar
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-row align-items-center justify-content-center">
        <component
          v-bind:is="transaction_type"
          @cancel="$emit('cancel')"
        ></component>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import DepositCredit from "../DepositCredit.vue";
import TransferCredit from "../TransferCredit.vue";
import WithdrawalCredit from "../WithdrawalCredit.vue";

export default {
  components: { TransferCredit, WithdrawalCredit, DepositCredit },
  emits: ["payMethodSelected", "registerTransferenciaVes", "cancel"],
  props: {
    // Credit_balance: Number,
  },
  data() {
    return {
      transaction_type: "DepositCredit",
      data_deposit: {},
      data_withdraw: {},
      credit_balance: 2.0,
      credit_balance_convert: 0,
      currency_acronym: "",
      pay_method_select: "",
      payLoad: "",
    };
  },
  methods: {
    ...mapActions("ticketoffice", [
      "generatePayMethods",
      "generateClientPayMethods",
      "generatePayMethodsDetail",
      "generateClientPayMethodsDetail",
      "creditDepositRequest",
      "creditWithdrawalRequest",
      "creditTransferRequest",
      "getUserBalance",
    ]),

    selectTransactiontype(type) {
      this.transaction_type = type;
    },
    payMethodSelected(pay_method_selected, convert_amount, currency_acronym) {
      this.pay_method_select = pay_method_selected;
      this.credit_balance_convert = convert_amount * this.credit_balance;
      this.currency_acronym = currency_acronym;
      if (pay_method_selected === 3) {
        this.data_deposit = {
          fk_payment_method_detail: pay_method_selected,
          amount: this.credit_balance,
        };
        this.creditDepositRequest(this.data_deposit);
      } else {
        this.generatePayMethodsDetail(pay_method_selected);
        this.getUserBalance();
      }
    },
    clientPayMethodSelected(
      pay_method_selected,
      convert_amount,
      currency_acronym
    ) {
      this.pay_method_select = pay_method_selected;
      this.credit_balance_convert = convert_amount * this.credit_balance;
      this.currency_acronym = currency_acronym;
      this.generateClientPayMethodsDetail(pay_method_selected);
    },
    registerTransferenciaVes(reference, date, pay_method_select) {
      this.data_deposit = {
        amount: this.credit_balance,
        fk_payment_method_detail: pay_method_select,
        external_reference: reference,
        request_date: date,
      };
      this.creditDepositRequest(this.data_deposit)
        .then((resp) => {
          if (resp.data.status == "SUCCESS") {
            this.$store.dispatch("changeSystemMessage", {
              severity: "success",
              detail: "Pago Solicitado con Éxito.",
              life: 4000,
            });
            this.getUserBalance();
          }
        })
        .catch((err) => {
          if (err.response) {
            this.$store.dispatch("changeSystemMessage", {
              severity: "error",
              summary: "Fallo al Registrar el Pago.",
              detail: err.response.data.errors[0].detail,
              life: 6000,
            });
          }
        });
      this.credit_balance = 0;
      this.credit_balance_convert = 0;
      this.$emit("cancel");
    },
    registerWithdrawal(pay_method_select) {
      this.data_withdraw = {
        fk_payment_method_detail: pay_method_select,
        amount: this.credit_balance,
      };
      this.creditWithdrawalRequest(this.data_withdraw)
        .then((resp) => {
          if (resp.data.status == "SUCCESS") {
            this.$store.dispatch("changeSystemMessage", {
              severity: "success",
              detail: "Retiro Solicitado con Éxito.",
              life: 4000,
            });
            this.getUserBalance();
          }
        })
        .catch((err) => {
          if (err.response) {
            this.$store.dispatch("changeSystemMessage", {
              severity: "error",
              summary: "Fallo al Registrar el retiro.",
              detail: err.response.data.errors[0].detail,
              life: 6000,
            });
          }
        });
      this.credit_balance = 0;
      this.credit_balance_convert = 0;
      this.$emit("cancel");
    },
    registerTransferCredit(mail) {
      this.payLoad = {
        amount: this.credit_balance,
        email_user_reciber: mail,
      };
      this.creditTransferRequest(this.payLoad)
        .then((resp) => {
          if (resp.data.status == "SUCCESS") {
            this.$store.dispatch("changeSystemMessage", {
              severity: "success",
              detail: "Transferencia Enviada con Éxito.",
              life: 4000,
            });
            this.getUserBalance();
          }
        })
        .catch((err) => {
          if (err.response) {
            this.$store.dispatch("changeSystemMessage", {
              severity: "error",
              summary: "Fallo al Registrar la transferencia.",
              detail: err.response.data.errors[0].detail,
              life: 6000,
            });
          }
        });
      this.$emit("cancel");
    },
    validateCreditBalance() {},
  },
  computed: {
    ...mapState("ticketoffice", ["payMethods", "payMethodsDetail"]),
  },
  created() {
    this.generatePayMethods();
  },
};
</script>

<style scoped>
.credit-modal {
  background-color: #ffff;
  border-radius: 10px;
  width: 670px;
}

.cancel-buttom {
  background-color: transparent;
  color: #000000;
  font-size: 2rem;
}
.cancel-buttom:hover {
  background-color: transparent;
  color: #cc195a;
}
.pi-wallet {
  color: #cc195a;
  font-size: 2rem;
}
h3 {
  margin-bottom: 0px;
}
.card-credit {
  background: rgba(215, 215, 215, 0.5);
  border-radius: 0.5rem;
}
.title-margin {
  margin-bottom: -1.5rem;
}
.buttom-group {
  width: 100%;
  height: 40px;
  background: rgba(230, 230, 230, 0.801);
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.082);
  border-radius: 6px;
}
.btn-credito {
  width: 10rem;
  height: 30px;
  padding: 0.3rem 1.5rem;
  margin: 1rem;
  font-family: "Roboto";
  font-weight: 500;
  font-style: normal;
  text-align: center;
  border-radius: 6px;
}
.btn-credito:hover {
  background-color: #cc195a;
  color: #fff;
  cursor: pointer;
}
.btn-credito:focus {
  background-color: #cc195a;
  color: #fff;
  cursor: pointer;
}
.btn-credito:active {
  background-color: #cc195a;
  color: #fff;
  cursor: pointer;
}

@media screen and (max-width: 390px) {
  .card-credit {
    width: 100%;
  }
}
.active-deposit-choice {
  background-color: #8c113e;
  color: #fff;
  cursor: pointer;
}
</style>
