import HTTP from "@/service/http";

export default {
  namespaced: true,
  state: {
    operators: [],
  },
  actions: {
    getOperators({ commit }) {
      return new Promise((resolve, reject) => {
        HTTP()
          .get("/operators")
          .then((resp) => {
            commit("setOperatorsList", resp.data);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getOperatorById(s, id) {
      return new Promise((resolve, reject) => {
        HTTP()
          .get("/operators/" + id)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    registerOperator(s, data) {
      return new Promise((resolve, reject) => {
        HTTP(true, 240000)
          .post("/operators", data)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    changeFileStatus(s, data) {
      return new Promise((resolve, reject) => {
        HTTP()
          .patch("/files/set-status-file/" + data.id, {
            status: data.status,
            annotation: data.annotation,
          })
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    consultRevisionStatus(s, data) {
      return new Promise((resolve, reject) => {
        HTTP(true)
          .get("/operators/register-status?rif=" + data.rif + "&prefix=" + data.prefix)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    downloadFile(s, id) {
      return new Promise((resolve, reject) => {
        HTTP(false, 4000, "blob")
          .get("/files/" + id)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    sendNotification(s, data) {
      return new Promise((resolve, reject) => {
        HTTP()
          .get("/operators/notify-review/" + data.operator_id)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  mutations: {
    setOperatorsList: (state, data) => {
      state.operators = data.data;
    },
  },
  getters: {
    operatorsList: (state) => {
      return state.operators ? state.operators : [];
    },
    requiredFiles: () => {
      return [
        {
          label: "Cédula del Representante Legal",
          key: "fk_dni_representative",
          tooltip:
            "Copia de la cédula de identidad del Acta o Documento Constitutivo debidamente registrado del operador, cuyo objeto sea exclusivamente la explotación de actividades de envite y azar.",
        },
        {
          label: "RIF del Representante Legal",
          key: "fk_rif_representative",
          tooltip: "Copia del RIF del Representante Legal vigente.",
        },
        {
          label: "Poder o Acta de Asamblea",
          key: "fk_assembly_minutes",
          tooltip:
            "Copia del poder o Acta de Asamblea registrada donde conste la cualidad de representación y copia de la Cedula de Identidad.",
        },
        {
          label:
            "Relación de cada uno de los Accionistas y del Representante Legal",
          key: "fk_shareholder_representative_relationship",
          tooltip:
            "Relación de cada uno de los Accionistas y del Representante Legal, en la cual se especifique documentos de identificación, nombres, apellidos, edad, Rif, nacionalidad, estado civil, domicilio, números de contacto y correos electrónicos personales, debidamente suscrita por el representante legal y con sello húmedo.",
        },
        {
          label: "RIF de la Empresa",
          key: "fk_company_rif_pdf",
          tooltip: "Copia del RIF de la Empresa vigente.",
        },
        {
          label: "Documento de Propiedad o del Contrato de Arrendamiento",
          key: "fk_leasing_contract",
          tooltip:
            "Copia del Documento de Propiedad o del Contrato de Arrendamiento del inmueble donde opera, debidamente autenticado.",
        },
        {
          label: "Licencia de Industria y Comercio",
          key: "fk_industry_commerce_license",
          tooltip: "Copia de Licencia de Industria y Comercio, vigente.",
        },
        {
          label: "Licencia emitida por la IOBPAS",
          key: "fk_iobpas_license",
          tooltip: "Copia de la Licencia emitida por la IOBPAS al Operador.",
        },
        {
          label: "Contrato suscrito entre el Operador y la IOBPAS",
          key: "fk_iobpas_contract",
          tooltip:
            "Copia del Contrato suscrito entre el Operador y la IOBPAS, debidamente autenticado.",
        },
        {
          label: "Estructura organizativa y manual organizacional",
          key: "fk_organizational_structure",
          tooltip:
            "Estructura organizativa y manual organizacional con plena adecuación a las normas sobre prevención, control y fiscalización de los delitos de legitimación de capitales y el financiamiento al terrorismo, en la actividad de juegos de Lotería.",
        },
        {
          label: "Declaración jurada de aceptación y dedicación exclusiva",
          key: "fk_exclusive_dedication_affidavit",
          tooltip:
            "Declaración jurada de aceptación y dedicación exclusiva del cargo de oficial de cumplimiento, síntesis curricular y la certificación de acta de junta contentiva de su designación.",
        },
        {
          label:
            "Certificado del registro ante el Registro Nacional de Contratistas",
          key: "fk_national_registry_contractors_certificate",
          tooltip:
            "Copia del certificado del registro ante el Registro Nacional de Contratistas, vigente.",
        },
        {
          label: "Comprobante de pago",
          key: "fk_payment_proof",
          tooltip: "Comprobante de pago por concepto de tasa de registro.",
        },
        {
          label: "Comprobante de aporte a la CONALOT",
          key: "fk_conalot_contribution_proof",
          tooltip:
            "Comprobante de aporte a la CONALOT derivado del trámite de registro.",
        },
        {
          label: "Registro Comercial",
          key: "fk_commercial_register",
          tooltip: "Registro Comercial.",
        },
        {
          label: "Solvencias",
          key: "fk_solvency",
          tooltip: "Solvencias.",
        },
      ];
    },
  },
};
