<template>
  <div
    class="surface-0 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden"
  >
    <div class="grid justify-content-center p-2 lg:p-0" style="min-width: 80%">
      <div class="col-12 mt-5 xl:mt-0 text-center">
        <img
          :src="'layout/images/logo-pagando.svg' "
          alt="Sakai logo"
          class="mb-5"
          style="width: 81px; height: 60px"
        />
      </div>
      <div
        class="col-12 xl:col-6"
        style="
          border-radius: 56px;
          padding: 0.3rem;
          background: linear-gradient(
            180deg,
            var(--primary-color),
            rgba(33, 150, 243, 0) 30%
          );
        "
      >
        <div
          class="h-full w-full m-0 py-7 px-4"
          style="
            border-radius: 53px;
            background: linear-gradient(
              180deg,
              var(--surface-50) 38.9%,
              var(--surface-0)
            );
          "
        >
          <div class="w-full md:w-10 mx-auto">
            <div class="login-form mb-3">
              <h2>Create Your Free Account</h2>
              <p>
                Already have an account?
                <router-link
                  class="font-medium no-underline ml-2 text-right cursor-pointer"
                  style="color: var(--primary-color)"
                  to="login"
                  >Login
                </router-link>
              </p>
            </div>
            <form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
              <div class="field">
                <div class="p-float-label p-input-icon-right">
                  <i class="pi pi-envelope" />
                  <InputText
                    id="email"
                    v-model="v$.email.$model"
                    :class="{ 'p-invalid': v$.email.$invalid && submitted }"
                    aria-describedby="email-error"
                  />
                  <label
                    for="email"
                    :class="{ 'p-error': v$.email.$invalid && submitted }"
                    >Email*</label
                  >
                </div>
                <span v-if="v$.email.$error && submitted">
                  <span
                    id="email-error"
                    v-for="(error, index) of v$.email.$errors"
                    :key="index"
                  >
                    <small class="p-error">{{ error.$message }}</small>
                  </span>
                </span>
                <small
                  v-else-if="
                    (v$.email.$invalid && submitted) ||
                    v$.email.$pending.$response
                  "
                  class="p-error"
                  >{{
                    v$.email.required.$message.replace("Value", "Email")
                  }}</small
                >
              </div>
              <div class="field">
                <div class="p-float-label">
                  <Password
                    id="password"
                    v-model="v$.password.$model"
                    :class="{ 'p-invalid': v$.password.$invalid && submitted }"
                    toggleMask
                  >
                    <template #header>
                      <h6>Pick a password</h6>
                    </template>
                    <template #footer="sp">
                      {{ sp.level }}
                      <Divider />
                      <p class="mt-2">Suggestions</p>
                      <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                        <li>At least one lowercase</li>
                        <li>At least one uppercase</li>
                        <li>At least one numeric</li>
                        <li>Minimum 8 characters</li>
                      </ul>
                    </template>
                  </Password>
                  <label
                    for="password"
                    :class="{ 'p-error': v$.password.$invalid && submitted }"
                    >Password*</label
                  >
                </div>
                <small
                  v-if="
                    (v$.password.$invalid && submitted) ||
                    v$.password.$pending.$response
                  "
                  class="p-error"
                  >{{
                    v$.password.required.$message.replace("Value", "Password")
                  }}</small
                >
              </div>
              <div class="field">
                <div class="p-float-label">
                  <Password
                    id="password2"
                    v-model="v$.password2.$model"
                    :class="{ 'p-invalid': v$.password2.$invalid && submitted }"
                    :feedback="false"
                    toggleMask
                  >
                  </Password>
                  <label
                    for="password2"
                    :class="{ 'p-error': v$.password2.$invalid && submitted }"
                    >Confirm Password*</label
                  >
                </div>
                <span v-if="v$.password2.$error && submitted">
                  <span
                    id="email-error"
                    v-for="(error, index) of v$.password2.$errors"
                    :key="index"
                  >
                    <small class="p-error">{{ error.$message }}</small>
                  </span>
                </span>
                <small
                  v-else-if="
                    (v$.password2.$invalid && submitted) ||
                    v$.password2.$pending.$response
                  "
                  class="p-error"
                  >{{
                    v$.password2.required.$message.replace(
                      "Value",
                      "Password confirmation"
                    )
                  }}</small
                >
              </div>
              <div class="field-checkbox">
                <Checkbox
                  id="accept"
                  name="accept"
                  value="Accept"
                  v-model="v$.accept.$model"
                  :class="{ 'p-invalid': v$.accept.$invalid && submitted }"
                />
                <label
                  for="accept"
                  :class="{ 'p-error': v$.accept.$invalid && submitted }"
                  >I agree to the terms and conditions*</label
                >
              </div>
              <Button type="submit" label="Submit" class="mt-2" :loading="loading" />
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { email, required, sameAs } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import messages from "../mixins/messages";

export default {
  setup: () => ({ v$: useVuelidate() }),
  mixins: [messages],
  data() {
    return {
      email: "",
      password: "",
      password2: "",
      accept: null,
      submitted: false,
      loading: false,
    };
  },
  methods: {
    handleSubmit(isFormValid) {
      this.submitted = true;
      if (!isFormValid) return;
      this.loading = true;
      this.$store
        .dispatch("auth/signup", {
          email: this.email,
          password: this.password,
          is_active: false,
        })
        .then((resp) => {
          this.showToast({
            severity: "info",
            detail: resp.data.message,
            life: 3000,
          });
          setTimeout(() => {
            this.$router.push("/");
          }, 3500);
        })
        .catch((err) => {
          this.showToast({
            severity: "error",
            summary: "Error",
            detail: err.response.message,
            life: 3000,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    logoColor() {
      if (this.$appState.darkTheme) return "white";
      return "dark";
    },
  },
  validations() {
    return {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
      password2: {
        required,
        sameAsPassword: sameAs(this.password),
      },
      accept: {
        required,
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.field {
  margin-bottom: 1.8rem;
}

.login-form {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-direction: column;
  flex-direction: column;
}
</style>
