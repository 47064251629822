import { createStore } from "vuex";

import auth from "./modules/auth";
import users from "./modules/users";
import configurations from "./modules/configurations";
import operators from "./modules/operators";
import marketers from "./modules/marketers";
import agencies from "./modules/agencies";
import ticketoffice from "./modules/ticketoffice";
import transactions from "./modules/transactions";
import userprofile from "./modules/userprofile";
import notification from "./modules/notification";
import paymentmethod from "./modules/paymentmethod";

export const store = createStore({
  state: {
    system_message: null,
    system_notification: null,
    show_sidebar: false,
  },
  mutations: {
    setSystem_message: (state, data) => {
      state.system_message = data;
    },
    setSystem_notification: (state, data) => {
      state.system_notification = data;
    },
    setShowSidebar: (state, data) => {
      state.show_sidebar = data;
    }
  },
  actions: {
    changeSystemMessage({ commit }, data) {
      commit("setSystem_message", data);
    },
    changeSystemNotification({ commit }, data) {
      commit("setSystem_notification", data);
    },
  },
  getters: {
    getSystem_message: (state) => {
      return state.system_message;
    },
    getSystem_notification: (state) => {
      return state.system_notification;
    },
    getShowSidebar: (state) => {
      return state.show_sidebar;
    }
  },
  modules: {
    auth,
    users,
    operators,
    marketers,
    agencies,
    ticketoffice,
    transactions,
    configurations,
    userprofile,
    notification,
    paymentmethod
  },
});
