<template>
  <div class="layout-topbar notprintContent">
    <div class="grid mt-1 topbar">
      <div class="col-12 md:col-7 lg:col-5 p-0">
        <games-list class="notprintContent" v-if="['/ticketoffice-online', '/ticketoffice'].includes($route.path)" />
      </div>
      <div class="col md:col-3 gap-2 flex align-center buttonContainer" style="align-items: center;">
        <Button class="center-buttons button flex flex-column justify-content-center align-items-center main-button" style="color: #404040; width: 9em; height: 100%" @click="toggleModelConsultPaymentOrders()"><IconTicket
          :style="{ width: '2.5rem', height: '3rem', }" 
        /><label for="">Reenvío y anulación de recibo</label></Button>
        <Button class="center-buttons button flex flex-column justify-content-center align-items-center main-button" style="color: #404040;  width: 9em; height: 100%" @click="toggleModalBalanceWithdrawal()"><IconSearch  :style="{width: '2.5rem', height: '3rem', }"/>
          <label for="">Consulta y retiro de saldo</label></Button>
      </div>
      <div
        class="col-12 md:col-5 lg:col-4 p-0 reference"
      >
        <div class="box-payment-services-topbar">
          <PaymentServicesTopbar
            @show-credit-modal="(show) => showCreditModal = show"
          />
        </div>
        <div class="box-user-options-menu ml-3">
          <UserOptionsMenu />
        </div>
      </div>
    </div>
    <CreditModal
      class="modal"
      v-if="this.showCreditModal"
      @cancel="showCreditModal = false"
    />
  </div>
</template>

<script>
import GamesList from "@/components/ticketoffice/GamesList.vue";
import CreditModal from "./components/ticketoffice/modals/CreditModal.vue";
import { mapMutations, mapActions, } from "vuex";
import PaymentServicesTopbar from "./components/payment-services/PaymentServicesTopbar.vue";
import UserOptionsMenu from "./components/topbar/UserOptionsMenu.vue";
import IconSearch from './components/svg/icons/IconSearch.vue';
import IconTicket from './components/svg/icons/IconTicket.vue';

export default {
  components: {
    CreditModal,
    GamesList,
    PaymentServicesTopbar,
    UserOptionsMenu,
    IconSearch,
    IconTicket
  },
  data() {
    return {
      showCreditModal: false,
    };
  },
  mounted() {
    this.getUserBalance()
  },
  methods: {
    ...mapMutations("ticketoffice", ["setGetUserBalance"]),
    ...mapActions("ticketoffice", [
      "loadTicketOfficeSession",
      "getUserBalance", "toggleModelConsultPaymentOrders"
      , "toggleModalBalanceWithdrawal"
    ]),
    onMenuToggle(event) {
      this.$emit("menu-toggle", event);
    },
    onTopbarMenuToggle(event) {
      this.$emit("topbar-menu-toggle", event);
    },
    topbarImage() {
      return this.$appState.darkTheme
        ? "layout/images/logo-pagando.svg"
        : "layout/images/logo-pagando.svg";
    },
  },
};
</script>

<style scoped lang="scss">
.buttonContainer{
  display: flex;
  justify-content: center;
  align-items: center;
}
.reference{
  margin-left: auto;
}
.main-button:hover, .main-button:focus{
  color:#fff !important;
  background-color: #CC195A !important;
}
.btn {
  font-family: Roboto;
  font-size: 1rem;
  font-weight: bold;
  height: 40px;
  background: #c4c4c4;
  color: #000000;
  border-color: #c4c4c4;
  border-style: none;
}
.btn2 {
  margin-left: 28%;
}
.numberButton {
  width: auto;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.creditButton {
  width: 70px;
  background: #c80000;
  color: rgba(255, 255, 255, 0.87);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-color: #c80000;
}
.creditButton:hover {
  cursor: pointer;
}
.img {
  width: 15px;
}
.app-topbar-user-options-menu {
  right: 0;
  position: absolute;
  height: 95%;
}
.box-payment-services-topbar {
  width: 86%;
  display: inline-block;
}
.box-user-options-menu {
  display: inline-block;
  position: relative;
  height: 97%;
}
.center-buttons {
  background: #fff;
  color: #404040;
  width: 48%;
  border: 1px solid #CC195A;
  border-radius: 6px;
  height: 100%;
}

@media screen and (max-width: 1200px) {
  .buttonContainer{
    display: none !important;
  }

}

@media (max-width: 912px) {
  .css {
    position: fixed;
  }
  .btn2 {
    margin-left: 35%;
  }
}

@media screen and (max-width: 1350px) {
  .box-payment-services-topbar {
    margin-left: -0.7em;
  }
  .box-item-user-options-menu {
    width: 3em;
  }
  .box-user-options-menu {
    margin-left: 0.8em !important;
  }
}
</style>
