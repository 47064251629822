export default {
  data: function () {
    return {
      banks: [
        { bank_code: "0156", bank_name: "100%BANCO" },
        {
          bank_code: "0172",
          bank_name: "BANCAMIGA BANCO MICROFINANCIERO, C.A.",
        },
        { bank_code: "0171", bank_name: "BANCO ACTIVO BANCO COMERCIAL, C.A." },
        { bank_code: "0166", bank_name: "BANCO AGRICOLA" },
        { bank_code: "0175", bank_name: "BANCO BICENTENARIO" },
        { bank_code: "0128", bank_name: "BANCO CARONI C.A. BANCO UNIVERSAL" },
        {
          bank_code: "0164",
          bank_name: "BANCO DE DESARROLLO DEL MICROEMPRESARIO",
        },
        { bank_code: "0102", bank_name: "BANCO DE VENEZUELA S.A.I.C.A." },
        { bank_code: "0114", bank_name: "BANCO DEL CARIBE C.A." },
        { bank_code: "0149", bank_name: "BANCO DEL PUEBLO SOBERANO C.A." },
        { bank_code: "0163", bank_name: "BANCO DEL TESORO" },
        { bank_code: "0115", bank_name: "BANCO EXTERIOR C.A." },
        { bank_code: "0003", bank_name: "BANCO INDUSTRIAL DE VENEZUELA." },
        {
          bank_code: "0173",
          bank_name: "BANCO INTERNACIONAL DE DESARROLLO C.A.",
        },
        { bank_code: "0105", bank_name: "BANCO MERCANTIL C.A." },
        { bank_code: "0191", bank_name: "BANCO NACIONAL DE CREDITO" },
        { bank_code: "0138", bank_name: "BANCO PLAZA" },
        { bank_code: "0108", bank_name: "BANCO PROVINCIAL BBVA" },
        { bank_code: "0104", bank_name: "BANCO VENEZOLANO DE CREDITO S.A." },
        { bank_code: "0168", bank_name: "BANCRECER S.A. BANCO DE DESARROLLO" },
        { bank_code: "0134", bank_name: "BANESCO BANCO UNIVERSAL" },
        { bank_code: "0177", bank_name: "BANFANB" },
        { bank_code: "0146", bank_name: "BANGENTE" },
        { bank_code: "0174", bank_name: "BANPLUS BANCO COMERCIAL C.A" },
        { bank_code: "0121", bank_name: "CORP BANCA" },
        { bank_code: "0157", bank_name: "DELSUR BANCO UNIVERSAL" },
        { bank_code: "0151", bank_name: "FONDO COMUN" },
        {
          bank_code: "0601",
          bank_name: "INSTITUTO MUNICIPAL DE CRÉDITO POPULAR",
        },
        { bank_code: "0169", bank_name: "MIBANCO BANCO DE DESARROLLO C.A." },
        { bank_code: "0137", bank_name: "SOFITASA" },
      ],
    };
  },
  computed: {
    banksSorted_byName: function () {
      return this.banks.sort((a, b) => {
        if (a.bank_name < b.bank_name) {
          return -1;
        }
        if (a.bank_name > b.bank_name) {
          return 1;
        }
        return 0;
      });
    },
    banksSorted_byCode: function () {
      return this.banks.sort((a, b) => {
        if (a.bank_code < b.bank_code) {
          return -1;
        }
        if (a.bank_code > b.bank_code) {
          return 1;
        }
        return 0;
      });
    }
  }
};
