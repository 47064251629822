<template>
  <Carousel
    :value="activeGameList"
    :numVisible="numVisible"
    :numScroll="numScroll"
    :responsiveOptions="responsiveOptions"
    class="game-list m-0 p-0"
    v-click-outside="hide"
    v-show="activeGameList.length"
  >
    <template #item="slotProps">
      <div
        class="flex car-item justify-content-center"
        @click="selectGame(slotProps.data.id)"
        v-bind:class="{ selected: slotProps.data.id === selectedGame.id }"
      >
        <div class="flex align-items-center justify-content-center">
          <img
            class=""
            :src="slotProps.data.card_image"
            :alt="slotProps.data.name"
          />
        </div>
      </div>
    </template>
  </Carousel>
</template>
<script>
import Carousel from "primevue/carousel";
import vClickOutside from "click-outside-vue3";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    Carousel,
  },
  data() {
    return {
      numVisible: 7,
      numScroll: 7,
      responsiveOptions: [
        {
          breakpoint: "400px",
          numVisible: 2,
          numScroll: 2,
        },
      ],
    };
  },
  productService: null,
  created() {
    this.setCarouselView()
    window.addEventListener('resize', this.setCarouselView);
    this.getActiveOperatorGames().catch((err) => {
      if (err.response) {
        this.$store.dispatch("changeSystemMessage", {
          severity: "error",
          summary: "Fallo al Cargar Listado de Juegos Activos.",
          life: 4000,
        });
      }
    });
  },
  mounted() {
    
  },
  methods: {
    ...mapActions("ticketoffice", ["getActiveOperatorGames"]),
    ...mapMutations("ticketoffice", ["setSelectedGame"]),
    selectGame(id) {
      let game = this.activeGameList.find((e) => e.id === id);
      if (game) this.setSelectedGame(game);
    },
    hide() {
      if (this.$parent.gameList_visible) this.$parent.gameList_visible = false;
    },
    setCarouselView() {
      const width = window.innerWidth;
      switch (true) {
        case width <= 400:
          this.numVisible = 2;
          this.numScroll = 2;
          break;
        case width <= 650:
          this.numVisible = 3;
          this.numScroll = 2;
          break;
        default:
          this.numVisible = 8;
          this.numScroll = 8;
      }
    },
  },
  computed: {
    ...mapGetters("ticketoffice", ["activeGameList", "selectedGame"]),
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
};
</script>
<style lang="scss">
.p-carousel-items-content {
  overflow: hidden;
  width: 100%;
  height: 154px;
  border: 1px solid #cc195a;
  border-radius: 6px;
  padding: 1px;
}
.p-carousel-items-container {
  .car-item {
    width: 113px;
    height: 150px;
    background: transparent;
    /* border-radius: 20px; */
    position: relative;
    text-align: center;
    cursor: pointer;
    margin-right: 3px;
    transition: all 0.4s;
  }
  .car-item:not(.selected):hover {
    transform: scale(1.02);
    box-shadow: 10px 10px 15px -15px rgba(0, 0, 0, 0.62);
  }
  .car-item.selected::after {
    content: "\e90a";
    font-family: primeicons;
    font-style: normal;
    font-weight: 600;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    display: inline-block;
    position: absolute;
    right: 0px;
    background-color: #ffffff;
    padding: 2px;
  }
  .p-carousel-item:last-of-type .car-item {
    margin-right: 0px;
  }
}

.game-list .p-carousel-indicators {
  padding: 0rem 0rem;
  display: none;
}
.game-list {
  /*max-width: calc(100% - 300px);*/
  min-width: 220px;
  .p-carousel-content .p-carousel-prev,
  .p-carousel-content .p-carousel-next {
    border: 0px !important;
    padding: 0.3rem;
  }
}
.game-list .p-carousel-item {
  max-width: 120px !important;
  width: unset !important;
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 480px) {
  .game-list .p-carousel-item {
    max-width: unset !important;
  }
}
</style>
