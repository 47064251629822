<template>
  <div class="landing-background bg-cover bg-no-repeat surface-0 overflow-hidden">
    <div class="landing-layout-topbar">
      <router-link to="/" class="flex align-items-center">
        <img
          :src="'layout/images/logo-pagando.svg' "
          alt="Logo"
          width="250" height="70"

          class="mr-0"
        />
      </router-link>
      <a
        class="cursor-pointer block lg:hidden text-700 p-ripple"
        v-ripple
        v-styleclass="{
          selector: '@next',
          enterClass: 'hidden',
          leaveToClass: 'hidden',
          hideOnOutsideClick: true,
        }"
      >
        <i class="pi pi-bars text-4xl"></i>
      </a>
      <div class="landing-layout-menu surface-0 align-items-center flex-grow-1 hidden lg:flex absolute lg:static w-full left-0 px-6 lg:px-0 z-2" style="top: 92%">
        <div class="flex justify-content-between flex-column lg:block border-top-1 lg:border-top-none surface-border py-3 lg:py-0 mt-3 lg:mt-0">
          <Button
            label="Inicia sesión"
            @click="$router.push('/login')"
            class="button-fondo text-lg px-4 m-2">
          </Button>         
        </div>
      </div>
    </div>

    <div class="grid grid-nogutter bg-cover bg-no-repeat surface-section text-800" id="hero" style="border-radius:0px; width: 100%; background:url(layout/images/banner-pagando.jpg);">
      <div class="col-12 md:col-6 lg:col-6 mt-8 md:mt-6 md:p-8 pt-8 p-3 text-center md:text-left flex align-items-center ">
        <section>
          <div class="justify-content-center text-white mb-3">
            <span class=" align-items-center block text-6xl text-white font-bold mb-1">Bienvenido a<b class="text-7xl font-bold"> PAGANDO</b></span>
          </div>
            <p class="mt-0 mb-4 text-white text-2xl">Ahorra tiempo y simplifica pagos con nuestra plataforma líder digital.</p>
            <Button label="ÚNETE YA"
            @click="$router.push('/login')"
            class="button-border text-xl px-6 mt-3"
            >
            </Button> 
        </section>
      </div>

      <div class="flex col-12 sm:col-6 md:col-6 lg:col-6 md:mt-8 justify-content-center overflow-hidden">
        <img src="layout/images/mockup-pagando-wallet.png" alt="Image" class="md:w-6 w-11 my-6 align-items-center">
      </div>
    </div>   
    <div class="px-3 md:px-8 py-4 mb-8 mt-5" id="features">   
      <div class="px-0 md:px-8 py-0 md:py-6">
        <div class="mb-3 px-4 md:px-0 font-bold text-4xl">
          <span class="text-900">Disfruta de nuestros, </span>
          <span class="text-primary-landing">productos y servicios.</span>
        </div>
        <div class="text-700 text-xl px-4 md:px-0 mb-6">Consigue hoy mismo tu ticket de Kriptorifa, Animalitos o Triple Gordo con <b class="font-bold">PAGANDO!</b>"</div>
        
        <div class="grid justify-content-center">
          <div class="list-product col-12 md:col-3 mb-4 px-3 text-center">
            <span class="product p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
              <img src="layout/images/carrousel-kriptorifa.jpg" class="w-full">
              <div class="text-900 mb-1 mt-3 text-xl font-medium">KRIPTORIFA</div>
                <div>
                  <span class="text-700 text-base line-height-1">La evolución del juego.</span>
                </div>
                <Button label="Compra tu ticket" @click="$router.push('/login')" class="button-fondo text-base px-6 mt-4 m-2">
                </Button>
            </span>
          </div>

          <div class="list-product col-12 md:col-3 mb-4 px-3 text-center">
            <span class="product p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
              <img src="layout/images/carrousel-triple-gordo.jpg" class="w-full">
            <div class="text-900 mb-1 mt-3 text-xl font-medium">TRIPLE GORDO</div>
              <div>
                <span class="text-700 text-base line-height-1">El juego LIDER en Venezuela.</span>
              </div>
              <Button label="Compra tu ticket" @click="$router.push('/login')" class="button-fondo text-base px-6 mt-4 m-2"></Button>
            </span>
          </div>

          <div class="list-product col-12 md:col-3 mb-4 px-3 text-center">
            <span class="product p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
              <img src="layout/images/carrousel-animalitos.jpg" class="w-full">
            <div class="text-900 mb-1 mt-3 text-xl font-medium">ANIMALITOS</div>
              <div>
                <span class="text-700 text-base line-height-1">El  juego que paga al instante.</span>
              </div>
              <Button label="Compra tu ticket" @click="$router.push('/login')" class="button-fondo text-base px-6 mt-4 m-2"></Button>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="hidden py-0 md:px-8 px-4 py-3 md:py-8 lg:px-8 lg:mx-8" id="features">
      <div class="surface-section px-4 py-8 md:px-6 lg:px-8">
        <div class="text-700 text-center">
          <div class="text-blue-600 font-bold mb-3"><i class="pi pi-discord"></i>&nbsp;POWERED BY DISCORD</div>
            <div class="text-900 font-bold text-5xl mb-3">Join Our Design Community</div>
              <div class="text-700 text-2xl mb-5">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Velit numquam eligendi quos.</div>
                <Button label="Join Now" icon="pi pi-discord" class="font-bold px-5 py-3 p-button-raised p-button-rounded white-space-nowrap"></Button>
        </div>
      </div>
    </div>

    <div class="surface-800">
      <div class="grid py-8 md:py-8 px-3 m-0 bg-cover text-center md:text-left" id="kiosco" style="border-radius:0px; width: 100%; background:url(layout/images/banner-kiosco.jpg);">
        <div class="flex col-12 md:col-6 lg:col-6 justify-content-center">
          <img src="layout/images/interfaz-kiosco.png" class="w-full interfaz md:w-7">
        </div>
        <div class="col-12 md:col-6 lg:col-6 pt-8 justify-content-center">
          <h3 class="font-light text-white text-3xl md:text-4xl mb-2">¡Lleva tu agencia al siguiente nivel con <b>PAGANDO KIOSKO</b></h3>
            <span class="font-light text-white text-xl mt-6">Transacciones financieras rápidas, seguras y sencillas para pagos online. Únete a nuestra comunidad.</span>
              <p class="font-light text-white sm:line-height-2 md:line-height-4 text-xl mt-3" style="max-width:800px;">Simplifica tus pagos online y aumenta tus ventas con PAGANDO KIOSKO.</p>
                <Button label="COMPRAR UN TICKET"
                @click="$router.push('/login')" 
                class="button-border border-white text-white text-2xl px-6 m-3"
                >
                </Button>
                <Button label="APRENDE MÁS" class="button-border border-white text-white text-2xl px-6 m-3"></Button>
        </div>
      </div>
    </div>
    <div class="hidden col-12 md:col-6 lg:col-12" id="statuscontult">
      <StatusConsult />
    </div>
    
    <div class="hidden surface-200 px-4 py-4 md:px-6 lg:px-8">
      <div class="text-center my-4 font-bold text-2xl">
        <span class="text-500">Con la confianza de los mejores de, </span>
        <span class="text-red-500">  venezuela y el mundo.</span>
      </div>
      <div class="text-center text-700 text-sm mb-3">Tu experiencia es valiosa en reunir a clientes, únete con nosotros para marcar la diferencia.</div>
        <div class="grid text-700 text-center">
          <div class="col-12 md:col-6 lg:col-3 justify-content-center"><img src="layout/images/cliente-Kriptorifa.png" class="w-6" alt="mockup"></div>
            <div class="col-12 md:col-6 lg:col-3 justify-content-center"><img src="layout/images/cliente-tiquera.png" class="w-6" alt="mockup"></div>
              <div class="col-12 md:col-6 lg:col-3 justify-content-center"><img src="layout/images/cliente-bancamiga.png" class="w-6" alt="mockup"></div>
                <div class="col-12 md:col-6 lg:col-3 justify-content-center"><img src="layout/images/cliente-triplegordo.png" class="w-6" alt="mockup"></div>
        </div>
    </div>
    
    <div class="landing-footer pt-5">
      <div class="pb-4 pb-8 px-0 md:px-8 mx-0 lg:mx-8" id="contact">
        <div class="grid">
          <div class="col-12 md:col-4" style="margin-top: 0rem">
             <div
                class="flex flex-wrap align-items-center justify-content-center md:justify-content-start md:mb-0 mb-3"
              >
              <img
                :src="'layout/images/logo-pagando-footer.svg' "
                alt="footer sections"
                width="300"                 
                class="mr-2"
               />
              <h4 class="font-medium text-3xl text-900"></h4>
            </div>
          </div>

          <div class="col-12 md:col-8">
            <div class="grid text-center md:text-left">
              <div class="hidden col-12 md:col-3">
                <h4 class="font-medium text-2xl line-height-3 mb-3 text-500">
                  Pagando
                </h4>
                <a
                  class="line-height-3 text-base block cursor-pointer mb-2 text-600"
                  >Nosotros</a
                >
                <a
                  class="line-height-3 text-base block cursor-pointer mb-2 text-600"
                  >Noticias</a
                >
                <a
                  class="line-height-3 text-base block cursor-pointer mb-2 text-600"
                  >Inversionistas</a
                >
                <a
                  class="line-height-3 text-base block cursor-pointer mb-2 text-600"
                  >Carreras</a
                >
                <a class="line-height-3 text-base block cursor-pointer text-600"
                  >Equipo de Medios</a
                >
              </div>

              <div class="hidden col-12 md:col-3 mt-4 md:mt-0">
                <h4 class="font-medium text-2xl line-height-3 mb-3 text-500">
                  Recursos
                </h4>
                <a
                  class="line-height-3 text-base block cursor-pointer mb-2 text-600"
                  >Manual de uso<img src="layout/images/new-badge.svg" class="ml-2" width="26" height="9"
                /> </a
                >
                <a
                  class="line-height-3 text-base block cursor-pointer mb-2 text-600"
                  >Learn</a
                >
                <a class="line-height-3 text-base block cursor-pointer text-600"
                  >Case Studies</a
                >
              </div>

              <div class="hidden col-12 md:col-3 mt-4 md:mt-0">
                <h4 class="font-medium text-2xl line-height-3 mb-3 text-500">
                  Communidad
                </h4>
                <a
                  class="line-height-3 text-base block cursor-pointer mb-2 text-600"
                  >Discord</a
                >
                <a
                  class="line-height-3 text-base block cursor-pointer mb-2 text-600"
                  >Eventos<img src="layout/images/new-badge.svg" class="ml-2" width="26" height="9"
                /></a>
                <a
                  class="line-height-3 text-base block cursor-pointer mb-2 text-600"
                  >FAQ</a
                >
                <a class="line-height-3 text-base block cursor-pointer text-600"
                  >Blog</a
                >
              </div>

              <div class="col-12 md:col-4 mt-4 md:mt-0">
                <h4 class="font-medium text-2xl line-height-3 mb-3 text-500">
                  Síguenos
                </h4>
                <hr>
                  <a href="https://www.facebook.com/profile.php?id=100087409444606" target="_blank"><i class="pi pi-facebook px-3 text-500" style="font-size: 2rem"></i></a>
                  <a href="https://twitter.com/PagandoTech" target="_blank"><i class="pi pi-twitter px-3 text-500" style="font-size: 2rem"></i></a>
                  <a href="https://www.youtube.com/channel/UCRYQbIzMSeE8_nqFIFBmjDA" target="_blank"><i class="pi pi-youtube px-3 text-500" style="font-size: 2rem"></i></a>
                  <a href="https://tecfin212.com" target="_blank"><i class="pi pi-tiktok px-3 text-500" style="font-size: 2rem"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid landing-footer-copyright px-4 md:px-8 lg:px-8 py-4">
          <div class="col-12 md:col-6 lg:col-6 text-center md:text-left">© 2023 Pagando.tech | Todos los derechos reservados</div>
            <div class="col-12 md:col-6 lg:col-6 text-center md:text-right lg:text-right landing-footer-links">Desarrollado por:<a class="landing-link" href="https://tecfin212.com" target="_blank">Tec-Fin212,C.A</a></div>
      </div>
    </div>
  </div>
</template>

<script>
import StatusConsult from "../components/operator/StatusConsult.vue"

export default {
  components: {
    "StatusConsult": StatusConsult,
  },
  methods: {
    smoothScroll(id) {
      document.querySelector(id).scrollIntoView({
        behavior: "smooth",
      });
    },
  },
  computed: {
    logoColor() {
      if (this.$appState.darkTheme) return "white";
      return "dark";
    },
  },
};
</script>

<style>
.product:hover{
  box-shadow: 0px 4px 10px rgba(64, 64, 64, 0.50), 0px 0px 2px rgb(0 0 0 / 72%), 0px 2px 16px rgb(64 64  64  / 50%) !important;
    transition: background-color .5s, border-color .5s, color .5s, box-shadow .5s, background-size .5s cubic-bezier(.64, .09, .08, 1);

}
.landing-link{
  color: #cc195a;
}
.button-fondo{
border: 1px solid #fff;
}
.button-border{
  border: 1px solid #fff;
    background: transparent;
}
.button-simple{
  border: 0px solid #fff;
    background: transparent;
    color: #cc195a;
}
.button-border:hover{
  border: 1px solid #fff !important;
  background: #fff !important;
  color: #cc195a !important;
}
.text-primary-landing {
    color: #cc195a;
}
.interfaz {
    z-index: 0;
    position: relative;
    top: -200px;
}

#hero {
  
    height: 100% !important;
    width: 100%;
  overflow: hidden;
}

@media screen and (min-width: 768px) {

  #hero {
    -webkit-clip-path: ellipse(150% 87% at 93% 13%);
    clip-path: ellipse(150% 87% at 93% 13%);
    height: 530px;
  }
  #kiosco {
    -webkit-clip-path: ellipse(150% 87% at 93% 13%);

    height: 530px;
    background: #404040;
  }
}
@media (min-width: 1024px) {
  #hero > div {
    width: 50%;
  }

}
@media screen and (min-width: 1300px) {
  #hero > img {
    position: absolute;
  }

  #hero > div > p {
    max-width: 450px;
  }
}

@media screen and (max-width: 1300px) {
  #hero {
    height: 600px;
  }
  .interfaz {
    z-index: 0;
    position: relative;
    top: 0px;
}
  #hero > img {
    position: static;
    transform: scale(1);
    margin-left: auto;
  }


  #hero > div > p {
    width: 100%;
    max-width: 100%;
  }

}
</style>
