<template>
  <div class="landing-layout-topbar">
    <router-link to="/" class="flex align-items-center">
      <img
        :src="'layout/images/logo-pagando.svg' "
        alt="Logo"
        width="169" height="35"
        class="mr-0 lg:ml-8"
      />
    </router-link>
    <a
      class="cursor-pointer block lg:hidden text-700 p-ripple"
      v-ripple
      v-styleclass="{
        selector: '@next',
        enterClass: 'hidden',
        leaveToClass: 'hidden',
        hideOnOutsideClick: true,
      }"
    >
      <i class="pi pi-bars text-4xl"></i>
    </a>
    <div
      class="landing-layout-menu surface-0 align-items-center flex-grow-1 hidden lg:flex absolute lg:static w-full left-0 px-6 lg:px-0 z-2"
      style="top: 92%"
    >
      <div
        class="flex justify-content-between lg:block border-top-1 lg:border-top-none surface-border py-3 lg:py-0 mt-3 lg:mt-0"
      >
        <Button
          label="Iniciar Sesión"
          @click="$router.push('/login')"
          class="landing-button-login p-button-text text-red-500"
        ></Button>
        <Button
          label="Registrar Operadora"
          @click="$router.push('/operator-register')"
          class="p-button-rounded border-none ml-5 font-light text-white line-height-2 bg-red-500"
        ></Button>
      </div>
    </div>
  </div>
  <div
    class="operador-background surface-0 flex align-items-center justify-content-center min-h-screen overflow-hidden"
  >
    <div
      class="operador-top grid justify-content-center m-2 my-8 p-2 lg:p-0"
      style="min-width: 80%"
    >
      <router-view />
    </div>
  </div>
  <div class="landing-footer pt-5">
    <div class="py-4 px-4 mx-0 mt-5 lg:mx-8" id="contact">
      <div class="grid justify-content-between">
        <div class="col-12 md:col-2" style="margin-top: -1.5rem">
          <div
            class="flex flex-wrap align-items-center justify-content-center md:justify-content-start md:mb-0 mb-3"
          >
            <img
              :src="'layout/images/logo-pagando.svg' "
              alt="footer sections"
              width="200"
              height="100"
              class="mr-2"
            />
            <h4 class="font-medium text-3xl text-900"></h4>
          </div>
        </div>

        <div class="col-12 md:col-7">
          <div class="grid text-center md:text-left">
            <div class="col-12 md:col-3">
              <h4 class="font-medium text-2xl line-height-3 mb-3 text-900">
                Empresa
              </h4>
              <a
                class="line-height-3 text-xl block cursor-pointer mb-2 text-700"
                >Nosotros</a
              >
              <a
                class="line-height-3 text-xl block cursor-pointer mb-2 text-700"
                >Noticias</a
              >
              <a
                class="line-height-3 text-xl block cursor-pointer mb-2 text-700"
                >Inversionistas</a
              >
              <a
                class="line-height-3 text-xl block cursor-pointer mb-2 text-700"
                >Carreras</a
              >
              <a class="line-height-3 text-xl block cursor-pointer text-700"
                >Equipo de Medios</a
              >
            </div>

            <div class="col-12 md:col-3 mt-4 md:mt-0">
              <h4 class="font-medium text-2xl line-height-3 mb-3 text-900">
                Recursos
              </h4>
              <a
                class="line-height-3 text-xl block cursor-pointer mb-2 text-700"
                >Get Started</a
              >
              <a
                class="line-height-3 text-xl block cursor-pointer mb-2 text-700"
                >Learn</a
              >
              <a class="line-height-3 text-xl block cursor-pointer text-700"
                >Case Studies</a
              >
            </div>

            <div class="col-12 md:col-3 mt-4 md:mt-0">
              <h4 class="font-medium text-2xl line-height-3 mb-3 text-900">
                Communidad
              </h4>
              <a
                class="line-height-3 text-xl block cursor-pointer mb-2 text-700"
                >Discord</a
              >
              <a
                class="line-height-3 text-xl block cursor-pointer mb-2 text-700"
                >Eventos<img src="layout/images/new-badge.svg" class="ml-2" width="26" height="9"
              /></a>
              <a
                class="line-height-3 text-xl block cursor-pointer mb-2 text-700"
                >FAQ</a
              >
              <a class="line-height-3 text-xl block cursor-pointer text-700"
                >Blog</a
              >
            </div>

            <div class="col-12 md:col-3 mt-4 md:mt-0">
              <h4 class="font-medium text-2xl line-height-3 mb-3 text-900">
                Marco Legal
              </h4>
              <a
                class="line-height-3 text-xl block cursor-pointer mb-2 text-700"
                >Políticas de Marca</a
              >
              <a
                class="line-height-3 text-xl block cursor-pointer mb-2 text-700"
                >Políticas de Privacidad</a
              >
              <a class="line-height-3 text-xl block cursor-pointer text-700"
                >Terminos y Condiciones</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="landing-footer-copyright">
      <div class="col-6 col-md-3">
        © 2022 Lotomax.site. Todos los derechos reservados
      </div>
      <div class="col-6 col-md-9 landing-footer-links">
        <a class="landing-link" href="#"></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {},

  computed: {
    logoColor() {
      if (this.$appState.darkTheme) return "white";
      return "dark";
    },
  },
};
</script>
