import HTTP from "@/service/http";

export default {
  namespaced: true,
  state: {
    agencies: [],
  },
  actions: {
    getAgencies({ commit }) {
      return new Promise((resolve, reject) => {
        HTTP()
          .get("/agencies")
          .then((resp) => {
            commit("setAgenciesList", resp.data);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    registerAgency(s, data) {
      return new Promise((resolve, reject) => {
        HTTP()
          .post("/agencies/partial", data)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getAgencyById(s, id) {
      return new Promise((resolve, reject) => {
        HTTP()
          .get("/agencies/" + id)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    verifyRegisterToken(s, token) {
      return new Promise((resolve, reject) => {
        HTTP(true)
          .get(`/agencies/partial?token=${token}`)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    registerAgencyFull(s, data) {
      return new Promise((resolve, reject) => {
        HTTP(true, 240000)
          .post("/agencies", data)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    sendNotification(s, data) {
      return new Promise((resolve, reject) => {
        HTTP()
          .post("/agencies/notify-review", data)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  mutations: {
    setAgenciesList: (state, data) => {
      state.agencies = data.data;
    },
  },
  getters: {
    agenciesList: (state) => {
      return state.agencies ? state.agencies : [];
    },
    requiredFiles: () => {
      return [
        {
          label: "Planilla de solicitud de registro",
          key: "fk_betting_center_registration",
          tooltip:
            "Planilla de solicitud de registro de centro de apuestas, debidamente firmada y sellada.",
        },
        {
          label: "Documento de Firma Personal o Constitutivo",
          key: "fk_constitutive_document",
          tooltip:
            "Si es persona natural, copia de documento de firma personal. En caso de ser persona jurídica, copia del Acta o Documento Constitutivo Estatutario, debidamente inscrito y sus modificaciones posteriores, si las hubiere. En ambos casos, el objeto social debe estar referido exclusivamente a la explotación de actividades de envite y azar.",
        },
        {
          label: "Poder o Acta de Asamblea",
          key: "fk_registered_assembly",
          tooltip:
            "Copia del poder o Acta de Asamblea registrada donde conste la cualidad de representación y copia de la Cedula de Identidad.",
        },
        {
          label: "RIF",
          key: "fk_rif",
          tooltip:
            "Copia del Rif, vigente.",
        },
        {
          label: "Cédula de Identidad del Representante Legal",
          key: "fk_representative_identity_card",
          tooltip:
            "Copia de la Cédula de Identidad del Representante Legal.",
        },
        {
          label: "Licencia de Industria y Comercio",
          key: "fk_trade_license",
          tooltip: "Copia de Licencia de Industria y Comercio, vigente.",
        },
        {
          label: "Licencia emitida por la IOBPAS",
          key: "fk_license_iobpas",
          tooltip:
            "Copia de la Licencia emitida por la IOBPAS al comercializador.",
        },
        {
          label: "Constancia de afiliación",
          key: "fk_proof_of_affiliation",
          tooltip:
            "Constancia de afiliación entre la Comercializadora y el Centro de Apuesta.",
        },
        {
          label: "Comprobante de pago",
          key: "fk_proof_of_payment",
          tooltip:
            "Comprobante de pago por concepto de tasa de registro.",
        },
        {
          label: "Comprobante de pago por aporte a la CONALOT",
          key: "fk_proof_of_payment_conalot",
          tooltip:
            "Comprobante de pago por concepto de aporte a la CONALOT.",
        },
      ];
    },
  },
};
