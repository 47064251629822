<template>
  <div v-if="this.send_credits == false">
    <h6 class="mt-5 mb-3 text-center">
      Ingrese el correo del usuario al que desea enviar los créditos.
    </h6>
    <div class="flex align-content-center">
      <div class="p-float-label flex-1">
        <InputText
          id="amount"
          class="label-input p-inputtext-sm"
          aria-autocomplete="off"
          autofocus
          autocomplete="off"
          v-model="creditAmount"
          type="number"
          :max="getTotalUserBalance"
          @keydown.enter="this.send_credits = true"
        />
        <label>Monto en BUSD <span class="text-red-500">*</span></label>
        <div v-if="show_validation_alert">
          <small class="p-error">Campo requerido.</small>
        </div>
      </div>
      <i
        class="pi pi-arrow-right m-3"
        style="font-size: 2rem; color: #40404077"
      ></i>
      <div class="p-float-label flex-1">
        <InputText
          id="mail"
          class="label-input p-inputtext-sm"
          aria-autocomplete="off"
          autofocus
          autocomplete="off"
          v-model="mail"
          type="email"
          @keydown.enter="this.send_credits = true"
        />
        <label>Correo electronico <span class="text-red-500">*</span></label>
        <div v-if="show_validation_alert">
          <small class="p-error">Campo requerido.</small>
        </div>
      </div>
    </div>
    <div class="flex justify-content-center mt-5 mb-3">
      <Button
        @click="sendCredits()"
        label="Confirmar"
        class="w-8rem btn-send-credit"
        :disabled="!this.mail || !this.creditAmount"
      />
    </div>

    </div>
    <div v-if="this.send_credits == true" class="flex justify-content-center flex-column m-4">
      <div class="flex flex-column justify-content-center text-center">
          <label style="font-size: 20px">Ingrese el código</label>
          <span class="text-highlight mb-3"  style="font-size: 20px"
            >Ingrese los seis dígitos del código de
            validación</span>
      </div>
      <VuePincodeInput
        v-model="verificationCode"
        :digits="code_digits"
        class="flex test justify-content-center flex-nowrap border-none border-noround text-xl font-semibold text-center"
        :autofocus="true"
      />
      <label 
        class="text-center sms-label mt-3 pt-3"
        @click="sendSMS()"
      >Enviar código por SMS.</label>
      <div class="buttons-container flex justify-content-evenly mt-5">
        <Button label="Atrás" class="button-actions return" @click="returnAndClean()" />
        <Button label="Continuar" class="button-actions confirm" @click="validateTransferCode()"/>
      </div>
    <!-- <TransactionStatus
      :credit_amount="this.credit_amount"
      v-show="this.send_credits == true"
      @cancel="this.send_credits = false"
    /> -->
  </div>
</template>

<script>
import HTTP from "@/service/http";
import Button from "primevue/button";
import VuePincodeInput from "vue3-pincode-input";

// import TransactionStatus from "./TransactionStatus";

import { mapGetters, mapActions } from "vuex";

export default {
  emits: ["cancel"],
  components: { Button, 
    VuePincodeInput,
    // TransactionStatus 
  },
  data() {
    return {
      mail: null,
      creditAmount: null,
      condition: false,
      show_validation_alert: false,
      send_credits: false,
      code_digits: 6,
      isFunctionBlocked: false
    };
  },
  methods: {
    ...mapActions("ticketoffice", ["getUserBalance"]),
    sendCredits() {
      let data = {
        user_reciber_mail: this.mail,
        real_amount_to_pay: this.creditAmount,
      };
      HTTP()
        .post("/transactions/transfer", data)
        .then((resp) => {
          this.$toast.add({
            severity: "success",
            detail: resp.data.message,
            life: 3000,
          });
          this.send_credits = true;
        })
        .catch((err) => {
          this.$toast.add({
            severity: "error",
            detail: err.response.data.message,
            life: 3000,
          });
        });
    },
    sendSMS() {
      if (this.isFunctionBlocked == false){ 
      let data = {
        user_reciber_mail: this.mail,
        real_amount_to_pay: this.creditAmount,
      };
      HTTP()
        .put("/transactions/transfer", data)
        .then((resp) => {
          this.$toast.add({
            severity: "success",
            detail: resp.data.message,
            life: 3000,
          });
          this.send_credits = true;
        })
        .catch((err) => {
          this.$toast.add({
            severity: "error",
            detail: err.response.data.message,
            life: 3000,
          });
        });
        setTimeout(() => {
          this.isFunctionBlocked = false;
        }, 8000);
      }
    },
    validateTransferCode() {
      let data = {
        verification_code: this.verificationCode,
      };
      HTTP()
        .patch("/transactions/transfer", data)
        .then((resp) => {
          this.$toast.add({
            severity: "success",
            detail: resp.data.message,
            life: 3000,
          });
          this.send_credits = false;
          this.getUserBalance();
          this.$emit("cancel");
        })
        .catch((err) => {
          this.$toast.add({
            severity: "error",
            detail: err.response.data.message,
            life: 3000,
          });
        });
    },
    returnAndClean(){
      this.send_credits = false;
      this.verificationCode = "";
      this.creditAmount = "";
      this.mail = "";
      this.$emit("cancel");
    },
  },
  computed: {
    ...mapGetters("ticketoffice", ["getTotalUserBalance"]),
  },
};
</script>

<style lang="scss" scoped>
.mail {
  color: #cc195a;
  font-size: large;
}

::v-deep .label-input {
  width: 15rem;
  color: #7d7d7d;
  background: transparent;
  border: none;
  border-bottom: 1px solid #7d7d7d;
  border-radius: 0px;
  font-family: "Roboto";
  font-size: 15px;
  font-weight: 400;
}

:deep.vue-pincode-input-wrapper .vue-pincode-input.default {
    width: 65px;
    height: 65px;
    border: 1px solid transparent;
    border-bottom: 2px solid rgb(204, 25, 90);
    font-size: 1.8rem;
}
:deep.vue-pincode-input-wrapper .vue-pincode-input.default:focus {
  box-shadow: none;
}
.btn-send-credit {
  background: #cc195a;
  height: 2rem;
  border-radius: 5px;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
}
.p-confirm-dialog-accept {
  background-color: #cc195a;
}
.send-credit-modal {
  background-color: #ffff;
  border-radius: 10px;
  width: 600px;
}
.confirm-header {
  margin-bottom: -1.5rem;
}
.cancel-buttom {
  background-color: transparent;
  color: #000000;
  font-size: 2rem;
}
.cancel-buttom:hover {
  background-color: transparent;
  color: #cc195a;
}
.pi-wallet {
  color: #cc195a;
  font-size: 2rem;
}
.confirm-Buttom {
  background-color: #cc195a;
  width: 5rem;
  height: 2.5rem;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 500;
}
.sms-label {
  color: #CC195A; 
  font-size: 15px; 
  font-weight: bold;
}
.sms-label:hover{
  cursor: pointer;
}
</style>
