<template>
  <div v-if="payment_method">
    <div
      v-for="field in payment_method.fk_payment_method_structure"
      :key="field.key"
      class="field grid"
    >
      <label for="field.key">{{ field.label }}</label>
      <InputText
        v-if="field.key !== 'bank_code' && field.key !== 'bank_name'"
        :id="'input_' + field.key"
        type="text"
        v-model="account[field.key]"
        :modelValue="account[field.key]"
        autocomplete="off"
        :disabled="is_processing_data"
        :class="{
          'p-invalid':
            v$.account[field.key] &&
            v$.account[field.key].$invalid &&
            submitted,
        }"
      />
      <Dropdown
        v-if="field.key === 'bank_code' || field.key === 'bank_name'"
        v-model="account[field.key]"
        :options="banksSorted_byCode"
        filter
        :optionLabel="'bank_name'"
        :optionValue="field.key"
        placeholder="Seleccione una opción ..."
        class="w-full"
        scrollHeight="350px"
        :filterFields="['bank_name', 'bank_code']"
        :autoFilterFocus="true"
        :resetFilterOnHide="true"
      >
        <template #value="slotProps">
          <div v-if="slotProps.value" class="flex align-items-center">
            <div>
              <b>{{ slotProps.value
              }}{{
                field.key === "bank_code"
                  ? " - " +
                    banks.find((e) => e.bank_code === slotProps.value).bank_name
                  : ""
              }}</b>
            </div>
          </div>
          <span v-else>
            {{ slotProps.placeholder }}
          </span>
        </template>
        <template #option="slotProps">
          <div class="flex align-items-center">
            <div v-if="field.key === 'bank_code'">
              <span class="mr-1"
                ><b>{{ slotProps.option.bank_code }}</b> -
              </span>
            </div>
            <div><b>{{ slotProps.option.bank_name }}</b></div>
          </div>
        </template>
      </Dropdown>
      <small
        v-if="
          v$.account[field.key] &&
          ((v$.account[field.key].$invalid && submitted) ||
            v$.account[field.key].$pending.$response)
        "
        class="p-error"
        >{{ v$.account[field.key].$silentErrors[0].$message }}</small
      >
    </div>
  </div>
</template>
<script>
import { requiredIf, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { isObject } from "@vue/shared";
import bank_list from "@/mixins/bank_list";
const valid_letters = /^[a-zA-ZñÑáéíóú]+(\s[a-zA-ZñÑáéíóú]+)?$/;

export default {
  name: "PayMethodForm",
  mixins: [bank_list],
  props: {
    payment_method: {
      type: Object,
      default: () => {
        return null;
      },
    },
    is_processing_data: {
      type: Boolean,
      default: false,
    },
    submitted: {
      type: Boolean,
      default: false,
    },
    form_options: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  components: {},
  data() {
    return {
      account: {},
    };
  },
  mounted() {
    this.setReadOnlyInputs();
    this.setInputsDefaultValues();
  },
  methods: {
    setReadOnlyInputs() {
      if (
        this.form_options &&
        this.form_options.read_only &&
        isObject(this.form_options.read_only)
      ) {
        Object.keys(this.form_options.read_only).forEach((key) => {
          let input = document.getElementById(`input_${key}`);
          if (input) input.setAttribute("readonly", true);
        });
      }
    },
    setInputsDefaultValues() {
      if (
        this.form_options &&
        this.form_options.default_values &&
        isObject(this.form_options.default_values)
      ) {
        this.payment_method.fk_payment_method_structure.forEach((field) => {
          if (this.form_options.default_values[field.key] !== undefined) {
            if (field.key === "bank_code" || field.key === "bank_name") {
              let bank = this.banks.find(
                (e) =>
                  e[field.key] === this.form_options.default_values[field.key]
              );
              if (bank) this.account[field.key] = bank[field.key];
            } else
              this.account[field.key] =
                this.form_options.default_values[field.key];
          }
        });
      }
    },
  },
  validations() {
    let val = {
      account: {},
    };
    if (this.payment_method)
      this.payment_method.fk_payment_method_structure.forEach((e) => {
        val.account[e.key] = {
          requiredIf: helpers.withMessage(
            "Este campo es requerido.",
            requiredIf(function () {
              return (
                !isObject(this.form_options.validations) ||
                !this.form_options.validations[e.key] ||
                !this.form_options.validations[e.key].required === false
              );
            })
          ),
          onlyNumbers: helpers.withMessage(
            "Este campo sólo admite números.",
            (value) => {
              return (
                !isObject(this.form_options.validations) ||
                !this.form_options.validations[e.key] ||
                !this.form_options.validations[e.key].only_numbers === true ||
                /^\d+$/.test(value)
              );
            }
          ),
          onlyLetters: helpers.withMessage(
            "Este campo sólo admite letras y espacio.",
            (value) => {
              return (
                !isObject(this.form_options.validations) ||
                !this.form_options.validations[e.key] ||
                !this.form_options.validations[e.key].only_letters === true ||
                valid_letters.test(value)
              );
            }
          ),
        };
      });
    return val;
  },
};
</script>
