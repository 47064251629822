<template>
	<div class="layout-menu-container">
		<AppSubmenu :items="model" class="layout-menu" :root="true" @menuitem-click="onMenuItemClick" @keydown="onKeyDown" />
	</div>
	<component :is="currentComponentMenu"></component>
</template>

<script>
import { defineAsyncComponent, markRaw } from 'vue';
import AppSubmenu from './AppSubmenu';

export default {
    emits: ['menuitem-click'],
    data() {
        return {
            currentComponentMenu: null,
        }
    },
	props: {
		model: Array
	},
    methods: {
        async onMenuItemClick(event) {
            this.$emit('menuitem-click', event);

			if (event.item.dispatch) {
				this.$store.dispatch(event.item.dispatch);
			}

			if (event.item.component)
			{
				this.currentComponentMenu = markRaw(await defineAsyncComponent(() => import(`@/components/${event.item.component}`)));
			}
        },
		onKeyDown(event) {
			const nodeElement = event.target;
			if (event.code === 'Enter' || event.code === 'Space') {
				nodeElement.click();
				event.preventDefault();
			}
		},
    },
	computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		}
	},
	components: {
		'AppSubmenu': AppSubmenu
	}
}
</script>