<template>
  <div>
    <div v-show="!loading" v-if="this.send_credits == false">
      <div
        class="mr-3 ml-3"
        v-if="this.paymethods && this.paymethods.length > 0"
      >
        <p class="instrcciontes-title">Instrucciones</p>
        <ul>
          <li>
            El retiro mínimo es de <b>1,00 BUSD</b>
            <img class="ml-1 w-1rem h-1rem" src="/images/busd12.png" />
          </li>
        </ul>
        <div class="text-center flex justify-content-center" v-show="!loading">
      <div class="flex flex-column">
      <label for="">Monto en BUSD</label>
      <InputText
        suffix=" BUSD"
        class="new-input mt-3"
        id="i_credit"
        mode="decimal"
        placeholder="0.00 (BUSD)"
        autofocus
        autocomplete="off"
        :min="20"
        @input="this.convertirDolaresABolivares"
        @keypress="this.validNumericInput"
        @paste="this.preventNonNumericInput"
        :minFractionDigits="2"
        :maxFractionDigits="2"
        :showButtons="false"
        :max="10000"
        @blur="validateCreditAmount()"
        v-model="v$.credit_amount.$model"
        style="height: 29px; font-size: 16px"
        ref="amountDepositeCredit"
        :class="{
          'p-invalid': v$.credit_amount.$invalid,
        }"
      />
      </div>
      <div class="flex flex-column">
      <label for="">Monto en Bolívares</label>
      <InputText
        suffix=" BS"
        class="new-input mt-3"
        id="i_credit"
        mode="decimal"
        @input="convertirBolivaresADolares"
        @keypress="validNumericInput"
        @paste="preventNonNumericInput"
        placeholder="0.00 (VES)"
        autofocus
        autocomplete="off"
        :min="2"
        :minFractionDigits="2"
        :maxFractionDigits="2"
        :showButtons="false"
        :max="10000"
        @blur="validateCreditAmount()"
        v-model="v$.amount_to_pay_ves.$model"
        style="height: 29px; font-size: 16px; margin-left: 20px"
        ref="amountDepositeCredit"
        :class="{
          'p-invalid': v$.amount_to_pay_ves.$invalid,
        }"
      />
      <div
        v-if="
          (v$.amount_to_pay_ves.$invalid && submitted) ||
          v$.amount_to_pay_ves.$pending.$response
        "
      >
        <small class="p-error">Monto requerido.</small>
      </div>
      </div>
    </div>


        <PayMethods
          :loading="!this.paymethods"
          :paymethods="this.paymethods"
          :credit_amount="this.credit_amount"
          @changePayMethod="changePayMethod"
        />
      </div>
      <div v-if="this.paymethods && this.paymethods.length === 0">
        <div class="mr-3 ml-3">
          <p class="instrcciontes-title">Instrucciones</p>
          <ul>
            <li>No posee datos bancarios para retirar créditos.</li>
          </ul>
          <p>
            Para registrar datos bancarios debe dirigirse a su <b>perfil</b> y
            luego a la sección de <b>Datos Bancarios</b>.
          </p>
          <Button
            label="Ir a Perfil"
            @click="
              {
                $router.push('/profile'), $emit('cancel');
              }
            "
            class="p-button-rounded border-none my-5 font-light text-white line-height-2 bg-red-500"
          >
          </Button>
        </div>
      </div>
      <TransferPayment
        :paymethod_selected="this.paymethod_selected"
        :credit_amount="this.credit_amount"
        :withdraw="true"
        :loading_confirm_button="this.loading_confirm_button"
        :required_fields="this.required_fields"
        @sendPaymentRequest="sendWithdrawRequest"
        v-show="this.paymethod_selected"
      />
    </div>
    <div v-if="this.send_credits == true" class="flex justify-content-center flex-column m-4">
      <div class="flex flex-column justify-content-center text-center mt-5">
          <label style="font-size: 20px">Ingrese el código</label>
          <span class="text-highlight mb-3"  style="font-size: 20px"
            >Ingrese los seis dígitos del código de
            validación</span>
      </div>
      <VuePincodeInput
        v-model="verificationCode"
        :digits="code_digits"
        class="flex justify-content-center flex-nowrap border-none border-noround text-xl font-semibold text-center"
        :autofocus="true"
      />
      <label 
      class="text-center sms-label mt-3 pt-3"
      @click="sendSMS()"
      >Enviar Código por SMS.</label>
      <div class="buttons-container flex justify-content-evenly mt-5">
        <Button label="Atrás" class="button-actions return" @click="returnAndClean()" />
        <Button label="Continuar" class="button-actions confirm" @click="validateWithdrawCode"/>
      </div>
    </div>
    <ProgressSpinner mode="indeterminate" class="m-2" v-show="loading" />
  </div>
</template>

<script>
import HTTP from "@/service/http";
import PayMethods from "./PayMethods";
import TransferPayment from "./TransferPayment";
import ProgressSpinner from "primevue/progressspinner";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import VuePincodeInput from "vue3-pincode-input";
import { mapMutations, mapState } from "vuex";

export default {
  components: {
    PayMethods,
    TransferPayment,
    ProgressSpinner,
    VuePincodeInput,
  },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      send_credits: false,
      required_fields: ["real_amount_to_pay", "paymethod_detail"],
      show_validation_alert: false,
      paymethods: null,
      paymethod_selected: null,
      credit_amount: null,
      loading_confirm_button: false,
      loading: true,
      submitted: false,
      validation_code: "",
      code_digits: 6,
      amount_to_pay_ves: null,
      paymethod_detail: null,
      real_amount_to_pay: null,
      isFunctionBlocked: false,
      verificationCode: ""
    };
  },
  
  computed: {
    ...mapState("ticketoffice", ["monedaOptions"]),
    ammount_to_pay() {
      if (this.credit_amount && this.paymethod_selected) {
        return (
          parseFloat(
            this.credit_amount * this.paymethod_selected.convert_amount
          ).toFixed(2) + ` ${this.paymethod_selected.currency_acronym}`
        );
      }

      return 0;
    },
  },
  created() {
    this.getPaymentmethodsWithdrawCredits();
  },
  methods: {
    ...mapMutations("ticketoffice", ["setGetUserBalance"]),
    returnAndClean(){
      this.send_credits = false;
      this.verificationCode = "";
      this.ammount_to_pay = "";
      this.credit_amount = "";
    },
    validateCreditAmount() {
      if (!this.credit_amount) {
        this.paymethod_selected = null;
      }
    },
    changePayMethod(value) {
      if (this.credit_amount) {
        this.paymethod_selected = value;
        this.show_validation_alert = false;
      } else {
        this.show_validation_alert = true;
        this.$refs.amountDepositeCredit.$el.focus();
        this.submitted = true;
      }
    },
    convertirDolaresABolivares() {
      this.amount_to_pay_ves =
        (Number(this.credit_amount) * this.convert(1)).toFixed(2)
    },
    convertirBolivaresADolares() {
      this.credit_amount = 
        Number(this.amount_to_pay_ves) / this.convert(1)
    },
    convert(amount) {
      let moneda = this.monedaOptions.find((e) => e.code === 1);
      return !isNaN(amount) && moneda && !isNaN(moneda.exchange_rate)
        ? Number(amount) * Number(moneda.exchange_rate)
        : Number(0);
    },
    validNumericInput(event) {
      if (
        !/\d/.test(event.key) &&
        (event.key !== "." || /\./.test(event.target.value))
      )
        return event.preventDefault();
    },
    preventNonNumericInput(event) {
      event.preventDefault();
      const text = event.clipboardData.getData("text/plain");
      const numeric = text.replace(/[^\d]/g, "");
      document.execCommand("insertText", false, numeric);
    },
    sendWithdrawRequest(data) {
      this.paymethod_detail = data.paymethod_detail
      this.real_amount_to_pay = data.real_amount_to_pay
      return new Promise((resolve, reject) => {
        this.loading_confirm_button = true;
        HTTP()
          .post("/transactions/withdraw", data)
          .then((resp) => {
            this.$toast.add({
              severity: "success",
              detail: resp.data.message,
              life: 3000,
            });
            this.send_credits = true;
            resolve(resp);
          })
          .catch((err) => {
            this.$toast.add({
              severity: "error",
              summary: err.response.data.status,
              detail: err.response.data.message,
              life: 3000,
            });
            reject(err);
          })
          .finally(() => (this.loading_confirm_button = false));
      });
    },
    sendSMS() {
      if (this.isFunctionBlocked == false){ 
      const sendData = {
        paymethod_detail: this.paymethod_detail,
        real_amount_to_pay: this.real_amount_to_pay
      }
      return new Promise((resolve, reject) => {
        HTTP()
          .put("/transactions/withdraw", sendData)
          .then((resp) => {
            this.$toast.add({
              severity: "success",
              detail: resp.data.message,
              life: 3000,
            });
            resolve(resp);
            setTimeout(() => {
              this.isFunctionBlocked = false;
            }, 8000);
          })
          .catch((err) => {
            this.$toast.add({
              severity: "error",
              summary: err.response.data.status,
              detail: err.response.data.message,
              life: 3000,
            });
            reject(err);
          })
      });
      }
    },
    validateWithdrawCode() {
      this.verification_code = Number(this.verificationCode)
      return new Promise((resolve, reject) => {
        this.loading_confirm_button = true;
        HTTP()
          .patch("/transactions/withdraw", { verification_code: this.verification_code })
          .then((resp) => {
            this.$toast.add({
              severity: "success",
              detail: resp.data.message,
              life: 3000,
            });
            console.log(resp);
            this.send_credits = false;
            this.verificationCode = ""
            this.setGetUserBalance({
              "balance": parseFloat(resp.data.data.balance).toFixed(2),
            });
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
            this.verificationCode = ""            
          })
          .finally(() => (this.loading_confirm_button = false));
      });
    },
    getPaymentmethodsWithdrawCredits() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        HTTP()
          .get("/paymentmethods?withdraw-credits")
          .then((resp) => {
            this.paymethods = resp.data.data;
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
  },
  validations() {
    return {
      credit_amount: {
        required,
      },
      amount_to_pay_ves: {
        required,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.instrcciontes-title {
  font-size: 18px;
  font-weight: 520;
}
:deep.vue-pincode-input-wrapper .vue-pincode-input.default {
    width: 65px;
    height: 65px;
    border: 1px solid transparent;
    border-bottom: 2px solid rgb(204, 25, 90);
    font-size: 1.8rem;
}
:deep.vue-pincode-input-wrapper .vue-pincode-input.default:focus {
  box-shadow: none;
}
.button-actions{
  width: 120px;
  font-size: 15px;
  font-weight: 700;
}
:deep .new-input {
  border-style: none;
  border-bottom: 1px solid rgb(204, 25, 90);
  border-radius: 0;
}
.sms-label {
  color: #CC195A; 
  font-size: 15px; 
  font-weight: bold;
}
.sms-label:hover{
  cursor: pointer;
}
</style>
